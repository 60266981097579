import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Box, Typography, TextField, Button, Switch, FormControlLabel, List, ListItem, ListItemText, Snackbar, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { doc, setDoc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { getAuth } from 'firebase/auth';
import { useMQTT } from '../contexts/MQTTContext'; // Adjust the import path as needed


const NetworkPage = React.memo(({ darkMode }) => 
  {  const navigate = useNavigate();
  const mqtt = useMQTT();
  const { 
    client, 
    connectionStatus, 
    lastMessage, 
    messages, 
    connect, 
    disconnect, 
    publish 
  } = mqtt || {};
  const [brokerUrl, setBrokerUrl] = useState('5bd6a499af1b420c8d5164a667fc7937.s1.eu.hivemq.cloud');
  const [port, setPort] = useState('8884');
  const [username, setUsername] = useState('AidaSub');
  const [password, setPassword] = useState('AidaSub1');
  const [topic, setTopic] = useState('Aida/Sub');
  const [isSecure, setIsSecure] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const showSnackbar = useCallback((message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  }, []);

  const loadConfig = useCallback(async () => {
    try {
      const docRef = doc(db, 'networkConfig', 'mqttSettings');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setBrokerUrl(data.brokerUrl || '5bd6a499af1b420c8d5164a667fc7937.s1.eu.hivemq.cloud');
        setPort(data.port || '8884');
        setUsername(data.username || 'AidaSub');
        setPassword(data.password || 'AidaSub1');
        setTopic(data.topic || 'Aida/Sub');
        setIsSecure(data.isSecure !== undefined ? data.isSecure : true);
      }
    } catch (error) {
      console.error("Error loading config:", error);
      showSnackbar("Failed to load configuration");
    }
  }, [showSnackbar]);

  useEffect(() => {
    loadConfig();
  }, [loadConfig]);

  const connectToBroker = useCallback(() => {
    const options = {
      protocol: isSecure ? 'wss' : 'ws',
      hostname: brokerUrl,
      port: parseInt(port),
      path: '/mqtt',
      username: username,
      password: password,
      clientId: `mqttjs_${Math.random().toString(16).substr(2, 8)}`,
      rejectUnauthorized: false,
      topic: topic
    };
    connect(options);
    showSnackbar('Connecting to MQTT broker...');
  }, [brokerUrl, port, username, password, topic, isSecure, connect, showSnackbar]);

  const disconnectFromBroker = useCallback(() => {
    disconnect();
    showSnackbar('Disconnected from MQTT broker');
  }, [disconnect, showSnackbar]);

  const publishTestMessage = useCallback(() => {
    const testMessage = JSON.stringify({ test: "Test message from Aida" });
    publish(topic, testMessage);
    showSnackbar('Test message published');
  }, [topic, publish, showSnackbar]);

  const saveConfigToFirestore = useCallback(async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      
      if (!user) {
        showSnackbar("User not authenticated");
        return;
      }
  
      const userUID = user.uid;
      const userDocRef = doc(db, 'users', userUID);
      const userDocSnap = await getDoc(userDocRef);
  
      if (!userDocSnap.exists()) {
        showSnackbar("User document does not exist");
        return;
      }
  
      const userData = userDocSnap.data();
      const businessName = userData.businessName;
  
      if (!businessName) {
        showSnackbar("No business name found for the user");
        return;
      }
  
      const settingsDocRef = doc(db, 'settings', businessName);
      const settingsDocSnap = await getDoc(settingsDocRef);
  
      const configData = {
        brokerUrl,
        port,
        username,
        password,
        topic,
        isSecure,
      };
  
      if (settingsDocSnap.exists()) {
        await updateDoc(settingsDocRef, { networkConfig: configData });
        showSnackbar("Configuration updated successfully");
      } else {
        await setDoc(settingsDocRef, { networkConfig: configData });
        showSnackbar("Configuration saved successfully");
      }
    } catch (error) {
      console.error("Error saving config:", error);
      showSnackbar("Failed to save configuration");
    }
  }, [brokerUrl, port, username, password, topic, isSecure, showSnackbar]);

  const saveConfigAndReconnect = useCallback(async () => {
    await saveConfigToFirestore();  // Save the new config
    if (client && client.connected) {
      disconnect(); // Disconnect first before reconnecting
    }
    connectToBroker(); // Reconnect using the updated configuration
  }, [client, disconnect, connectToBroker, saveConfigToFirestore]);

  const messagesList = useMemo(() => (
    <List>
      {messages.map((msg, index) => (
        <ListItem key={index}>
          <ListItemText 
            primary={`${msg.topic}: ${msg.message}`} 
            secondary={msg.timestamp.toLocaleString()} 
          />
        </ListItem>
      ))}
    </List>
  ), [messages]);

  const handleBack = useCallback(() => {
    navigate('/settings');
  }, [navigate]);

  return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          minHeight: 'calc(100vh - 64px)',
          position: 'relative',
          zIndex: 1,
          marginTop: '64px',
          padding: '24px'
        }}
      >
        <Box sx={{ width: '100%', maxWidth: '1200px' }}>
          <Typography 
            variant="h4" 
            sx={{ 
              mb: 3,
              color: darkMode ? 'white' : 'inherit'
            }}
          >
            MQTT Configuration
          </Typography>
          <Box sx={{ display: 'flex', gap: 3, flexDirection: { xs: 'column', md: 'row' } }}>
            <Paper 
              elevation={3} 
              sx={{ 
                p: 3,
                backgroundColor: darkMode ? 'rgba(66, 66, 66, 0.9)' : 'rgba(255, 255, 255, 0.9)',
                borderRadius: 2,
                width: { xs: '100%', md: '300px' }
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <TextField 
                  label="Broker URL"
                  value={brokerUrl}
                  onChange={(e) => setBrokerUrl(e.target.value)}
                  sx={{ 
                    '& label': { color: darkMode ? 'rgba(255, 255, 255, 0.7)' : undefined },
                    '& input': { color: darkMode ? 'white' : undefined }
                  }}
                />
                <TextField 
                  label="Port"
                  value={port}
                  onChange={(e) => setPort(e.target.value)}
                  sx={{ 
                    '& label': { color: darkMode ? 'rgba(255, 255, 255, 0.7)' : undefined },
                    '& input': { color: darkMode ? 'white' : undefined }
                  }}
                />
                <TextField 
                  label="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  sx={{ 
                    '& label': { color: darkMode ? 'rgba(255, 255, 255, 0.7)' : undefined },
                    '& input': { color: darkMode ? 'white' : undefined }
                  }}
                />
                <TextField 
                  label="Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  sx={{ 
                    '& label': { color: darkMode ? 'rgba(255, 255, 255, 0.7)' : undefined },
                    '& input': { color: darkMode ? 'white' : undefined }
                  }}
                />
                <TextField 
                  label="Topic to Subscribe"
                  value={topic}
                  onChange={(e) => setTopic(e.target.value)}
                  sx={{ 
                    '& label': { color: darkMode ? 'rgba(255, 255, 255, 0.7)' : undefined },
                    '& input': { color: darkMode ? 'white' : undefined }
                  }}
                />
                <FormControlLabel
                  control={
                    <Switch 
                      checked={isSecure} 
                      onChange={() => setIsSecure(!isSecure)}
                      sx={{
                        '& .MuiSwitch-switchBase.Mui-checked': {
                          color: 'green',
                          '&:hover': {
                            backgroundColor: 'rgba(0, 255, 0, 0.08)',
                          },
                        },
                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                          backgroundColor: 'green',
                        },
                      }}
                    />
                  }
                  label="Use Secure Connection (WSS)"
                  sx={{ color: darkMode ? 'white' : 'inherit' }}
                />
                <Button 
                  variant="contained" 
                  onClick={connectionStatus === 'Connected' ? saveConfigAndReconnect : connectToBroker}
                >
                  {connectionStatus === 'Connected' ? "Update" : "Connect"}
                </Button>
                <Button 
                  variant="contained" 
                  onClick={disconnectFromBroker} 
                  disabled={connectionStatus !== 'Connected'}
                >
                  Disconnect
                </Button>
                <Button 
                  variant="contained" 
                  onClick={publishTestMessage} 
                  disabled={!client}
                >
                  Publish Test Message
                </Button>
                <Button 
                  variant="contained" 
                  onClick={saveConfigToFirestore}
                >
                  Save Config
                </Button>
                <Typography sx={{ color: darkMode ? 'white' : 'inherit' }}>
                  Status: {connectionStatus}
                </Typography>
                <Typography sx={{ color: darkMode ? 'white' : 'inherit' }}>
                  Last Message: {lastMessage}
                </Typography>
              </Box>
            </Paper>
  
            <Paper 
              elevation={3} 
              sx={{ 
                p: 3,
                backgroundColor: darkMode ? 'rgba(66, 66, 66, 0.9)' : 'rgba(255, 255, 255, 0.9)',
                borderRadius: 2,
                flexGrow: 1,
                maxHeight: '600px',
                overflow: 'auto'
              }}
            >
              <Typography 
                variant="h6" 
                gutterBottom
                sx={{ color: darkMode ? 'white' : 'inherit' }}
              >
                Received Messages:
              </Typography>
              {messagesList}
            </Paper>
          </Box>
  
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button 
              onClick={handleBack}
              sx={{ color: darkMode ? 'rgba(255, 255, 255, 0.7)' : 'text.secondary' }}
            >
              Back
            </Button>
          </Box>
        </Box>
  
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
        />
      </Box>
  );
});

export default NetworkPage;
