import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  TextField,
  List,
  ListItem,
  Divider,
  MenuItem,
  FormControl,
  InputAdornment,
  IconButton,
  Popover,
  Snackbar
} from '@mui/material';
import { Save as SaveIcon, ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useCompanySettings } from '../contexts/CompanySettingsContext';
import mqtt from 'mqtt'; 
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Make sure to import mqtt

const ConfigurationPage = ({ darkMode }) => {
  const navigate = useNavigate();
  const { companySettings, updateCompanySettings, loading, error } = useCompanySettings();

  const [step, setStep] = useState(1);
  const [lineCount, setLineCount] = useState(1);
  const [drinkLines, setDrinkLines] = useState([]);
  const [configError, setConfigError] = useState('');
  const [isCustom, setIsCustom] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedLineId, setSelectedLineId] = useState(null);
  const [errors, setErrors] = useState({});
  const [client, setClient] = useState(null);
  const [mqttSettings, setMqttSettings] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const showSnackbar = useCallback((message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  }, []);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };


  const presetAmounts = [1, 2, 4, 8, 16, 32, 64, 'Custom'];
  const alcoholTypes = [
    "Whiskey", "Rum", "Tequila", "Vodka", "Gin", "Cachaça", "Pisco", "Cognac", 
    "Campari", "Triple Sec", "Sweet Vermouth", "Brandy", "Bourbon", "Scotch", 
    "Amaretto", "Absinthe", "Mezcal", "Aperol", "Amaro", "Frangelico", "Sambuca"
  ];

  

  useEffect(() => {
    if (companySettings?.drinkLinesConfig) {
      setLineCount(companySettings.drinkLinesConfig.length);
      setDrinkLines(companySettings.drinkLinesConfig);
    }
  }, [companySettings]);

  const updateDrinkLines = useCallback(() => {
    setDrinkLines(prevLines => {
      const existingLines = prevLines.slice(0, lineCount);
      const newLines = Array(Math.max(0, lineCount - existingLines.length)).fill().map((_, index) => ({ 
        id: existingLines.length + index + 1, 
        name: `Line ${existingLines.length + index + 1}`,
        drink: '',
        alcoholType: '' 
      }));
      return [...existingLines, ...newLines];
    });
  }, [lineCount]);
  
  useEffect(() => {
    updateDrinkLines();
  }, [updateDrinkLines, lineCount]);

  useEffect(() => {
    updateDrinkLines();
  }, [updateDrinkLines]);

  const handleLineCountChange = (event) => {
    const value = event.target.value;
    if (value === 'Custom') {
      setIsCustom(true);
      setLineCount('');
    } else {
      setIsCustom(false);
      const parsedValue = parseInt(value, 10);
      if (isNaN(parsedValue) || parsedValue < 1 || parsedValue > 64) {
        setConfigError('Please enter a non-negative integer between 1 and 64.');
      } else {
        setLineCount(parsedValue);
        setConfigError('');
      }
    }
    setIsDropdownOpen(false);
  };



  const fetchMqttSettings = useCallback(async () => {
    try {
      const docRef = doc(db, 'networkConfig', 'mqttSettings');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setMqttSettings(docSnap.data());
      } else {
        console.log("No MQTT settings found!");
        showSnackbar("MQTT settings not found. Please configure network settings.");
      }
    } catch (error) {
      console.error("Error fetching MQTT settings:", error);
      showSnackbar("Failed to fetch MQTT settings");
    }
  }, [showSnackbar]);

  useEffect(() => {
    fetchMqttSettings();
  }, [fetchMqttSettings]);

  useEffect(() => {
    if (mqttSettings) {
      const { brokerUrl, port, username, password, isSecure } = mqttSettings;
      const url = `${isSecure ? 'wss' : 'ws'}://${brokerUrl}:${port}/mqtt`;
      
      const mqttClient = mqtt.connect(url, {
        username,
        password,
        clientId: `mqttjs_${Math.random().toString(16).substr(2, 8)}`,
        rejectUnauthorized: false
      });

      mqttClient.on('connect', () => {
        console.log('Connected to MQTT broker');
        setClient(mqttClient);
      });

      mqttClient.on('error', (err) => {
        console.error('MQTT connection error:', err);
        showSnackbar('Failed to connect to MQTT broker');
      });

      return () => {
        if (mqttClient) {
          mqttClient.end();
        }
      };
    }
  }, [mqttSettings, showSnackbar]);

  const handleSaveConfig = async () => {
    if (validateConfig()) {
      const configWithInventory = drinkLines.map(line => ({
        ...line,
        drink: ''
      }));
    
      try {
        await updateCompanySettings({
          ...companySettings,
          drinkLinesConfig: configWithInventory
        });
        console.log('Configuration saved:', configWithInventory);
        
        // Publish the configuration via MQTT
        if (client && mqttSettings) {
          const topic = mqttSettings.topic || 'Aida/Sub';
          const message = JSON.stringify(configWithInventory);
          client.publish(topic, message, (err) => {
            if (err) {
              console.error('Failed to publish configuration:', err);
              showSnackbar('Failed to publish configuration');
            } else {
              console.log('Configuration published successfully');
              showSnackbar('Configuration saved and published successfully');
            }
          });
        } else {
          showSnackbar('Configuration saved but MQTT client not connected');
        }
        
        navigate('/inventory');
      } catch (error) {
        console.error('Error saving configuration:', error);
        showSnackbar('Failed to save configuration. Please try again.');
      }
    } else {
      showSnackbar('Please assign alcohol types to all lines');
    }
  };


  const handleCustomInputChange = (event) => {
    const value = event.target.value;
    const parsedValue = parseInt(value, 10);
    if (value === '') {
      setLineCount('');
      setConfigError('');
    } else if (isNaN(parsedValue) || parsedValue < 1 || parsedValue > 64) {
      setLineCount(value);
      setConfigError('Please enter a non-negative integer between 1 and 64.');
    } else {
      setLineCount(parsedValue);
      setConfigError('');
    }
  };

  const handleNextStep = () => setStep(2);
  const handlePrevStep = () => setStep(1);

  const handleLineNameChange = (id, newName) => {
    setDrinkLines(prevLines => prevLines.map(line => 
      line.id === id ? { ...line, name: newName } : line
    ));
  };

  const handleAlcoholTypeClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedLineId(id);
  };

  const handleAlcoholTypeSelect = (newType) => {
    setDrinkLines(prevLines => prevLines.map(line => 
      line.id === selectedLineId ? { ...line, alcoholType: newType } : line
    ));
    setAnchorEl(null);
    setErrors(prevErrors => ({
      ...prevErrors,
      [selectedLineId]: undefined
    }));
  };

  const handleDropdownClick = (event) => {
    event.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  const validateConfig = () => {
    const newErrors = {};
    drinkLines.forEach(line => {
      if (!line.alcoholType) {
        newErrors[line.id] = "Please assign an alcohol type";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };



  const handleBackToSettings = () => {
    navigate('/settings');
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        minHeight: 'calc(100vh - 64px)',
        position: 'relative',
        zIndex: 1,
        marginTop: '64px',
        padding: '24px'
      }}
    >
      <Box sx={{ width: '100%', maxWidth: '800px' }}>
        <Typography variant="h4" gutterBottom sx={{ color: darkMode ? 'white' : 'text.primary' }}>
          Configuration
        </Typography>
        <Paper elevation={3} sx={{ 
          p: 3, 
          mb: 2, 
          backgroundColor: darkMode ? 'rgba(66, 66, 66, 0.9)' : 'rgba(255, 255, 255, 0.9)',
          borderRadius: 2
        }}>
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6" sx={{ color: darkMode ? 'rgba(255, 255, 255, 0.7)' : 'text.secondary', mb: 2 }}>
              {step === 1 ? "1. Select Number of Lines" : "2. Assign Line Names and Alcohol Types"}
            </Typography>
            {step === 1 ? (
              <FormControl fullWidth error={!!configError}>
                {isCustom ? (
                  <TextField
                    label="Number of Drink Lines"
                    value={lineCount}
                    onChange={handleCustomInputChange}
                    error={!!configError}
                    helperText={configError}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleDropdownClick}>
                            <ArrowDropDownIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                ) : (
                  <TextField
                    select
                    label="Number of Drink Lines"
                    value={lineCount}
                    onChange={handleLineCountChange}
                    error={!!configError}
                    helperText={configError}
                    SelectProps={{
                      open: isDropdownOpen,
                      onOpen: () => setIsDropdownOpen(true),
                      onClose: () => setIsDropdownOpen(false),
                      IconComponent: (props) => (
                        <IconButton {...props} onClick={handleDropdownClick}>
                          <ArrowDropDownIcon />
                        </IconButton>
                      ),
                    }}
                  >
                    {presetAmounts.map((num) => (
                      <MenuItem key={num} value={num}>{num}</MenuItem>
                    ))}
                  </TextField>
                )}
              </FormControl>
            ) : (
              <List sx={{ maxHeight: '300px', overflowY: 'auto' }}>
                {drinkLines.map((line, index) => (
                  <React.Fragment key={line.id}>
                    {index > 0 && <Divider />}
                    <ListItem sx={{ flexDirection: 'column', alignItems: 'stretch' }}>
                      <Box sx={{ display: 'flex', width: '100%', mb: 1 }}>
                        <TextField
                          fullWidth
                          label={`Line ${line.id}`}
                          value={line.name}
                          onChange={(e) => handleLineNameChange(line.id, e.target.value)}
                          variant="outlined"
                          size="small"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={(e) => handleAlcoholTypeClick(e, line.id)}>
                                  <ArrowDropDownIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      {errors[line.id] && (
                        <Typography color="error" variant="caption">
                          {errors[line.id]}
                        </Typography>
                      )}
                      <Typography 
                        variant="caption" 
                        sx={{ 
                          alignSelf: 'flex-end',
                          color: darkMode ? 'rgba(255, 255, 255, 0.7)' : 'text.secondary'
                        }}
                      >
                        {line.alcoholType || 'Not Assigned'}
                      </Typography>
                    </ListItem>
                  </React.Fragment>
                ))}
              </List>
            )}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button
              onClick={step === 1 ? handleBackToSettings : handlePrevStep}
              sx={{ color: darkMode ? 'rgba(255, 255, 255, 0.7)' : 'text.secondary' }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={step === 2 ? handleSaveConfig : handleNextStep}
              startIcon={step === 2 ? <SaveIcon /> : null}
              disabled={!!configError || lineCount === ''}
            >
              {step === 2 ? 'Save Configuration' : 'Next'}
            </Button>
          </Box>
        </Paper>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{
            style: { 
              maxHeight: '300px',
              backgroundColor: darkMode ? '#424242' : '#fff' 
            },
          }}
        >
          {alcoholTypes.map((type) => (
            <MenuItem 
              key={type} 
              onClick={() => handleAlcoholTypeSelect(type)}
              sx={{
                color: darkMode ? '#fff' : 'inherit'
              }}
            >
              {type}
            </MenuItem>
          ))}
        </Popover>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
        />
      </Box>
    </Box>
  );
}

export default ConfigurationPage;