import { createTheme } from '@mui/material/styles';

const commonComponents = {
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: '16px',
      },
    },
  },
};

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#bdbdbd', // light grey
    },
    secondary: {
      main: '#e0e0e0', // lighter grey
    },
    background: {
      default: '#f5f5f5', // very light grey
      paper: '#ffffff', // white
    },
    text: {
      primary: '#424242', // dark grey
      secondary: '#757575', // medium grey
    },
  },
  components: {
    ...commonComponents,
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          color: '#424242',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        },
      },
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',
    },
    secondary: {
      main: '#f48fb1',
    },
    background: {
      default: '#121212',
      paper: '#1d1d1d',
    },
    text: {
      primary: '#ffffff',
      secondary: '#aaaaaa',
    },
  },
  components: {
    ...commonComponents,
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#1d1d1d',
          color: '#ffffff',
          boxShadow: '0 4px 8px rgba(255, 255, 255, 0.2)',
        },
      },
    },
  },
});
