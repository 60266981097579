import React, { useEffect, useState } from 'react';
import { Card, Typography, Box, CircularProgress, Avatar, Grid, Divider, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, FormControl, InputLabel, IconButton } from '@mui/material';
import { getFirestore, doc, getDoc, updateDoc, deleteDoc, collection, getDocs } from 'firebase/firestore';
import { getAuth, deleteUser, updateProfile, updateEmail } from 'firebase/auth';
import CloseIcon from '@mui/icons-material/Close';

const AccountPage = ({ darkMode }) => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editedMember, setEditedMember] = useState(null);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [personalEditDialogOpen, setPersonalEditDialogOpen] = useState(false);
  const [editedPersonalInfo, setEditedPersonalInfo] = useState(null);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [memberToRemove, setMemberToRemove] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const auth = getAuth();
        const currentUser = auth.currentUser;

        if (currentUser) {
          setCurrentUserId(currentUser.uid);
          const db = getFirestore();
          const userDocRef = doc(db, 'users', currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            setUserData({ ...userDocSnap.data(), uid: currentUser.uid });
          } else {
            console.error('No user data found!');
          }
        } else {
          console.error('No user authenticated!');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleRemoveMemberClick = (member) => {
    setMemberToRemove(member);
    setRemoveDialogOpen(true);
  };

  const handleRemoveDialogClose = () => {
    setRemoveDialogOpen(false);
    setMemberToRemove(null);
  };

  const handleConfirmRemove = async () => {
    if (memberToRemove) {
      try {
        const db = getFirestore();
        const auth = getAuth();

        // Update current user's teamMembers list
        const updatedTeamMembers = userData.teamMembers.filter((tm) => tm.uid !== memberToRemove.uid);
        await updateDoc(doc(db, 'users', userData.uid), { teamMembers: updatedTeamMembers });

        // Remove member from all users' teamMembers lists
        const usersCollection = await getDocs(collection(db, 'users'));
        usersCollection.forEach(async (userDoc) => {
          const user = userDoc.data();
          if (user.teamMembers) {
            const updatedTeam = user.teamMembers.filter((tm) => tm.uid !== memberToRemove.uid);
            if (updatedTeam.length !== user.teamMembers.length) {
              await updateDoc(doc(db, 'users', userDoc.id), { teamMembers: updatedTeam });
            }
          }
        });

        // Delete member from Firestore
        const userDocRef = doc(db, 'users', memberToRemove.uid);
        await deleteDoc(userDocRef);

        // Delete member from Firebase Authentication
        const userToDelete = await auth.getUser(memberToRemove.uid);
        if (userToDelete) {
          await deleteUser(userToDelete);
        }

        setUserData((prevData) => ({ ...prevData, teamMembers: updatedTeamMembers }));
        setRemoveDialogOpen(false);
        setMemberToRemove(null);
      } catch (error) {
        console.error('Error removing member:', error);
      }
    }
  };

  const handleEditMember = (member) => {
    setEditedMember(member);
    setEditDialogOpen(true);
  };

  const handleSaveMember = async () => {
    try {
      const db = getFirestore();
      const auth = getAuth();
      const user = auth.currentUser;

      const updatedTeamMembers = userData.teamMembers.map((tm) =>
        tm.uid === editedMember.uid ? editedMember : tm
      );
      await updateDoc(doc(db, 'users', userData.uid), { teamMembers: updatedTeamMembers });

      if (editedMember.uid === user.uid) {
        if (user.displayName !== `${editedMember.firstName} ${editedMember.lastName}`) {
          await updateProfile(user, {
            displayName: `${editedMember.firstName} ${editedMember.lastName}`,
          });
        }
        if (user.email !== editedMember.email) {
          await updateEmail(user, editedMember.email);
        }
      }

      setUserData((prevData) => ({ ...prevData, teamMembers: updatedTeamMembers }));
      setEditDialogOpen(false);
      setEditedMember(null);
    } catch (error) {
      console.error('Error saving member:', error);
    }
  };

  const handleDialogClose = () => {
    setEditDialogOpen(false);
    setEditedMember(null);
  };

  const handleEditPersonalInfo = () => {
    setEditedPersonalInfo({
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
    });
    setPersonalEditDialogOpen(true);
  };

  const handleSavePersonalInfo = async () => {
    try {
      const db = getFirestore();
      const auth = getAuth();
      const user = auth.currentUser;

      await updateDoc(doc(db, 'users', userData.uid), editedPersonalInfo);

      if (user.displayName !== `${editedPersonalInfo.firstName} ${editedPersonalInfo.lastName}`) {
        await updateProfile(user, {
          displayName: `${editedPersonalInfo.firstName} ${editedPersonalInfo.lastName}`,
        });
      }
      if (user.email !== editedPersonalInfo.email) {
        await updateEmail(user, editedPersonalInfo.email);
      }

      setUserData((prevData) => ({ ...prevData, ...editedPersonalInfo }));
      setPersonalEditDialogOpen(false);
      setEditedPersonalInfo(null);
    } catch (error) {
      console.error('Error saving personal info:', error);
    }
  };

  const handlePersonalDialogClose = () => {
    setPersonalEditDialogOpen(false);
    setEditedPersonalInfo(null);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          backgroundColor: '#f5f5f5',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        minHeight: 'calc(100vh - 64px)',
        position: 'relative',
        zIndex: 1,
        marginTop: '64px',
        padding: '24px'
      }}
    >
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Card
          sx={{
            padding: 4,
            maxWidth: '600px',
            boxShadow: 3,
            borderRadius: 2,
            backgroundColor: darkMode ? 'rgba(66, 66, 66, 0.9)' : 'rgba(255, 255, 255, 0.9)',
            color: darkMode ? 'white' : 'inherit'
          }}
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <Avatar
              sx={{ 
                width: 80, 
                height: 80, 
                marginBottom: 2,
                bgcolor: darkMode ? 'primary.dark' : 'primary.main'
              }}
              alt={userData?.firstName}
            >
              {userData?.firstName ? userData.firstName.charAt(0) : 'U'}
            </Avatar>
            <Typography variant="h4" align="center" gutterBottom color={darkMode ? 'white' : 'inherit'}>
              {userData?.firstName} {userData?.lastName}
            </Typography>
            <Typography variant="subtitle1" align="center" color={darkMode ? 'rgba(255, 255, 255, 0.7)' : 'textSecondary'}>
              {userData?.businessName}
            </Typography>
            <Divider sx={{ 
              width: '100%', 
              marginY: 3,
              borderColor: darkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)'
            }} />
          </Box>
  
          <Box sx={{ width: '100%' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography variant="h6" color={darkMode ? 'white' : 'inherit'}>
                Personal Information
              </Typography>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={handleEditPersonalInfo}
              >
                Edit
              </Button>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body2" color={darkMode ? 'rgba(255, 255, 255, 0.7)' : 'textSecondary'}>
                  First Name
                </Typography>
                <Typography variant="body1" color={darkMode ? 'white' : 'inherit'}>
                  {userData?.firstName}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color={darkMode ? 'rgba(255, 255, 255, 0.7)' : 'textSecondary'}>
                  Last Name
                </Typography>
                <Typography variant="body1" color={darkMode ? 'white' : 'inherit'}>
                  {userData?.lastName}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color={darkMode ? 'rgba(255, 255, 255, 0.7)' : 'textSecondary'}>
                  Email
                </Typography>
                <Typography variant="body1" color={darkMode ? 'white' : 'inherit'}>
                  {userData?.email}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color={darkMode ? 'rgba(255, 255, 255, 0.7)' : 'textSecondary'}>
                  Business Name
                </Typography>
                <Typography variant="body1" color={darkMode ? 'white' : 'inherit'}>
                  {userData?.businessName}
                </Typography>
              </Grid>
            </Grid>
  
            {userData?.teamMembers && userData.teamMembers.length > 0 && (
              <>
                <Divider sx={{ 
                  marginY: 3,
                  borderColor: darkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)'
                }} />
                <Typography variant="h6" gutterBottom color={darkMode ? 'white' : 'inherit'}>
                  Team Members
                </Typography>
                {userData.teamMembers
                  .filter((member) => member.uid !== currentUserId)
                  .map((member, index) => (
                    <Box key={index} display="flex" alignItems="center" mb={2}>
                      <Typography variant="body1" sx={{ 
                        flexGrow: 1,
                        color: darkMode ? 'white' : 'inherit'
                      }}>
                        {member.firstName} {member.lastName} ({member.email})
                      </Typography>
                      {userData.permissions === 'admin' && (
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleRemoveMemberClick(member)}
                          sx={{ marginRight: 1 }}
                        >
                          Remove
                        </Button>
                      )}
                      {(userData.permissions === 'admin' || userData.permissions === 'editor') && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleEditMember(member)}
                          sx={{ marginRight: 1 }}
                        >
                          Edit
                        </Button>
                      )}
                    </Box>
                  ))}
              </>
            )}
          </Box>
        </Card>
      )}
  
      {/* Dialogs with dark mode support */}
      {editedMember && (
        <Dialog 
          open={editDialogOpen} 
          onClose={handleDialogClose}
          PaperProps={{
            sx: {
              backgroundColor: darkMode ? 'rgb(66, 66, 66)' : 'white',
              color: darkMode ? 'white' : 'inherit'
            }
          }}
        >
          <DialogTitle>
            Edit Team Member
            <IconButton
              aria-label="close"
              onClick={handleDialogClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: darkMode ? 'rgba(255, 255, 255, 0.7)' : undefined
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TextField
              label="First Name"
              variant="outlined"
              fullWidth
              margin="dense"
              value={editedMember.firstName}
              onChange={(e) => setEditedMember((prev) => ({ ...prev, firstName: e.target.value }))}
              sx={{ 
                '& label': { color: darkMode ? 'rgba(255, 255, 255, 0.7)' : undefined },
                '& input': { color: darkMode ? 'white' : undefined }
              }}
            />
            {/* Add similar TextField styling for Last Name, Email */}
            {userData.permissions === 'admin' && (
              <FormControl 
                fullWidth 
                margin="dense"
                sx={{
                  '& label': { color: darkMode ? 'rgba(255, 255, 255, 0.7)' : undefined },
                  '& .MuiSelect-select': { color: darkMode ? 'white' : undefined }
                }}
              >
                <InputLabel>Permissions</InputLabel>
                <Select
                  value={editedMember.permissions}
                  label="Permissions"
                  onChange={(e) => setEditedMember((prev) => ({ ...prev, permissions: e.target.value }))}
                >
                  <MenuItem value="admin">Admin</MenuItem>
                  <MenuItem value="editor">Editor</MenuItem>
                  <MenuItem value="viewer">Viewer</MenuItem>
                </Select>
              </FormControl>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSaveMember} color="primary" variant="contained">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
  
      {/* Edit Personal Information Dialog */}
      {editedPersonalInfo && (
        <Dialog open={personalEditDialogOpen} onClose={handlePersonalDialogClose}>
          <DialogTitle>
            Edit Personal Information
            <IconButton
              aria-label="close"
              onClick={handlePersonalDialogClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TextField
              label="First Name"
              variant="outlined"
              fullWidth
              margin="dense"
              value={editedPersonalInfo.firstName}
              onChange={(e) =>
                setEditedPersonalInfo((prev) => ({ ...prev, firstName: e.target.value }))
              }
            />
            <TextField
              label="Last Name"
              variant="outlined"
              fullWidth
              margin="dense"
              value={editedPersonalInfo.lastName}
              onChange={(e) =>
                setEditedPersonalInfo((prev) => ({ ...prev, lastName: e.target.value }))
              }
            />
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="dense"
              value={editedPersonalInfo.email}
              onChange={(e) =>
                setEditedPersonalInfo((prev) => ({ ...prev, email: e.target.value }))
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handlePersonalDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSavePersonalInfo} color="primary" variant="contained">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
  
      {/* Confirm Remove Member Dialog */}
      <Dialog
        open={removeDialogOpen}
        onClose={handleRemoveDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Delete Member"}
          <IconButton
            aria-label="close"
            onClick={handleRemoveDialogClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to permanently delete this team member?
          </Typography>
          {memberToRemove && (
            <Typography variant="body2" sx={{ mt: 2 }}>
              {memberToRemove.firstName} {memberToRemove.lastName} ({memberToRemove.email})
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRemoveDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmRemove} color="secondary" variant="contained" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AccountPage;