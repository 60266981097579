import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { 
  doc, 
  setDoc, 
  getDocs, 
  collection, 
  query, 
  where, 
  updateDoc,
  writeBatch,
  arrayUnion 
} from 'firebase/firestore';
import { auth, db } from '../firebase'; // Update the path if necessary
import { Card, TextField, Button, Typography, Box, Alert } from '@mui/material';
import { useTheme, alpha } from '@mui/material/styles';

const RegisterPage = () => {
  const theme = useTheme(); // Access the current theme
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      console.log('Starting registration process...');

      // 1. Create a new user using Firebase Authentication
      console.log('Creating new user with email:', email);
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      console.log('User created successfully. User ID:', user.uid);

      // Check if the user is authenticated
      if (!auth.currentUser) {
        console.error('User is not authenticated');
        throw new Error('User is not authenticated');
      }

      // 2. Prepare the new user document
      const normalizedBusinessName = businessName.trim().toLowerCase();
      const newUserDoc = {
        firstName,
        lastName,
        businessName: normalizedBusinessName,
        email,
        permissions: 'viewer',
        teamMembers: [],
      };

      // 3. Save the new user data to Firestore
      console.log('Attempting to create user document in Firestore...');
      try {
        await setDoc(doc(db, 'users', user.uid), newUserDoc);
        console.log('User document created successfully.');
      } catch (firestoreError) {
        console.error('Error creating user document:', firestoreError);
        throw firestoreError;
      }

      // 4. Query for other users with the same business name
      console.log('Querying for other users with business name:', normalizedBusinessName);
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('businessName', '==', normalizedBusinessName));
      let querySnapshot;
      try {
        querySnapshot = await getDocs(q);
        console.log(`Found ${querySnapshot.size} users with the same business name.`);
      } catch (queryError) {
        console.error('Error querying for users:', queryError);
        throw queryError;
      }

      // Initialize teamMembers array and Firestore batch
      const teamMembers = [];
      const batch = writeBatch(db);

      // 5. Loop through existing users and update their teamMembers
      querySnapshot.forEach((existingUser) => {
        if (existingUser.id !== user.uid) {
          console.log(`Processing existing user: ${existingUser.id}`);
          const existingUserData = existingUser.data();
          
          teamMembers.push({
            firstName: existingUserData.firstName,
            lastName: existingUserData.lastName,
            email: existingUserData.email,
            uid: existingUser.id,
          });

          batch.update(doc(db, 'users', existingUser.id), {
            teamMembers: arrayUnion({
              firstName,
              lastName,
              email,
              uid: user.uid,
            }),
          });
        }
      });

      // 6. Commit the batch write for updating other users' teamMembers
      if (!querySnapshot.empty) {
        console.log('Attempting to commit batch updates for team members...');
        try {
          await batch.commit();
          console.log('Batch updates committed successfully.');
        } catch (batchError) {
          console.error('Error committing batch updates:', batchError);
          throw batchError;
        }
      }

      // 7. Update the new user's teamMembers in Firestore
      console.log('Attempting to update new user document with team members...');
      try {
        await updateDoc(doc(db, 'users', user.uid), { teamMembers });
        console.log('New user document updated with team members.');
      } catch (updateError) {
        console.error('Error updating new user document:', updateError);
        throw updateError;
      }

      console.log('Registration process completed successfully.');
      setLoading(false);

      // 8. Redirect to the next page (order page)
      navigate('/order');
    } catch (error) {
      setLoading(false);
      console.error('Detailed error:', error);
      if (error.code) {
        console.error('Error code:', error.code);
      }
      setError('Failed to create an account: ' + (error.message || 'Unknown error'));
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        minHeight: 'calc(100vh - 64px)',
        position: 'relative',
        zIndex: 1,
        marginTop: '64px',
        padding: '24px',
      }}
    >
      <Card
        sx={{
          padding: 4,
          width: '400px',
          boxShadow: 3,
          borderRadius: 2,
          backgroundColor: alpha(theme.palette.background.paper, 0.9),
        }}
      >
        <Typography variant="h4" align="center" gutterBottom>
          Create Account
        </Typography>
        {error && (
          <Alert severity="error" sx={{ marginBottom: 2 }}>
            {error}
          </Alert>
        )}
        <form onSubmit={handleSubmit}>
          <TextField
            label="First Name"
            fullWidth
            margin="normal"
            variant="outlined"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <TextField
            label="Last Name"
            fullWidth
            margin="normal"
            variant="outlined"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          <TextField
            label="Business Name"
            fullWidth
            margin="normal"
            variant="outlined"
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
            required
          />
          <TextField
            label="Email"
            type="email"
            fullWidth
            margin="normal"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <TextField
            label="Password"
            type="password"
            fullWidth
            margin="normal"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            inputProps={{ minLength: 6 }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            sx={{ marginTop: 2, padding: '10px' }}
          >
            {loading ? 'Registering...' : 'Register'}
          </Button>
        </form>
        <Box mt={2} textAlign="center">
          <Typography variant="body2">
            Already have an account?{' '}
            <Link
              to="/login"
              style={{
                textDecoration: 'none',
                color: theme.palette.text.secondary,
              }}
            >
              Login here
            </Link>
          </Typography>
        </Box>
      </Card>
    </Box>
  );
};

export default RegisterPage;
