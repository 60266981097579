// Import necessary libraries and components
import React, { useState, useEffect, useCallback } from "react";
import { useMQTT } from "../contexts/MQTTContext"; // Import the useMQTT hook
import {
  CardContent,
  CardMedia,
  Typography,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Fade,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Slider,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit"; // Icon for edit button
import CloseIcon from "@mui/icons-material/Close"; // Icon for close button
import SwapHorizIcon from "@mui/icons-material/SwapHoriz"; // Icon for toggling between Base and Custom drinks
import { ref, getDownloadURL } from "firebase/storage"; // Firebase storage functions
import { doc, getDoc, setDoc } from "firebase/firestore"; // Firestore functions
import { storage, db } from "../firebase"; // Firebase instances
import { getAuth } from "firebase/auth"; // Firebase authentication
import "../styles/Card.css"; // Custom styles for the card component

// Card component definition
const CardComponent = ({
  drink, // Drink object with details
  darkMode, // Boolean indicating if dark mode is enabled
  onOrderDrink, // Callback when a drink is ordered
  onEdit, // Callback when editing starts
  isEditing, // Boolean indicating if the card is in edit mode
  onCloseEdit, // Callback when editing is closed
}) => {
  // State variables
  const [isFlipped, setIsFlipped] = useState(false); // Card flip state
  const [imageLoaded, setImageLoaded] = useState(false); // Image load state
  const [imageURL, setImageURL] = useState(null); // Image URL
  const [showUnavailableDialog, setShowUnavailableDialog] = useState(false); // Dialog visibility
  const [unavailableIngredients, setUnavailableIngredients] = useState([]); // Unavailable ingredients
  const [configError, setConfigError] = useState(false); // Configuration error state
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar visibility
  const [snackbarMessage, setSnackbarMessage] = useState(""); // Snackbar message
  const [originalSliderValues, setOriginalSliderValues] = useState({}); // Original ingredient amounts
  const [sliderValues, setSliderValues] = useState({}); // Current ingredient amounts
  const [instructions, setInstructions] = useState(drink.instructions || ""); // Instructions
  const [currentDrink, setCurrentDrink] = useState(() => {
    const initialDrink = JSON.parse(JSON.stringify(drink));
    if (initialDrink.ingredients) {
      initialDrink.ingredients = initialDrink.ingredients.map((ing) => {
        if (ing.type === "poured" && !ing.alcoholType) {
          return { ...ing, alcoholType: ing.ingredient };
        }
        return ing;
      });
    }
    return initialDrink;
  });  
  const [userRole, setUserRole] = useState(null); // User's role
  const [businessName, setBusinessName] = useState(""); // User's business name
  const [isCustomDrink, setIsCustomDrink] = useState(false); // Custom drink flag
  const [customDrink, setCustomDrink] = useState(null); // Custom drink data
  const [price, setPrice] = useState(
    parseFloat((drink.price || "").replace("$", "")) || ""
  ); // Drink price
  const [loadingUserData, setLoadingUserData] = useState(true); // Loading state for user data
  const [drinkLinesConfig, setDrinkLinesConfig] = useState([]); // Drink lines configuration
  const [availableLiquors, setAvailableLiquors] = useState({}); // Available liquors per alcohol type
  const [isAvailable, setIsAvailable] = useState(true); // Availability of the drink

  // Import the 'publish' function from useMQTT
  const { client, connectionStatus, mqttSettings, publish } = useMQTT(); // MQTT context

  // Fetch user data (role and business name) from Firestore
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const auth = getAuth();
        const currentUser = auth.currentUser;

        if (currentUser) {
          const userDocRef = doc(db, "users", currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setUserRole(userData.permissions || "viewer");
            setBusinessName(userData.businessName || "");
          } else {
            console.error("No user data found!");
            setUserRole("viewer");
          }
        } else {
          console.error("No user authenticated!");
          setUserRole("viewer");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        setUserRole("viewer");
      } finally {
        setLoadingUserData(false);
      }
    };

    fetchUserData();
    // Removed 'setLoadingUserData' from dependencies as it's a stable setter function
  }, []);

  // Fetch drink lines configuration from Firestore
  useEffect(() => {
    const fetchDrinkLinesConfig = async () => {
      try {
        // Fetch from 'settings/Aida' as per your Firestore structure
        const settingsDocRef = doc(db, "settings", "Aida");
        const settingsDocSnap = await getDoc(settingsDocRef);

        if (settingsDocSnap.exists()) {
          const settingsData = settingsDocSnap.data();
          const config = settingsData.drinkLinesConfig || [];
          setDrinkLinesConfig(config);

          // Build available liquors mapping
          const liquors = {};
          config.forEach((line) => {
            if (!liquors[line.alcoholType]) {
              liquors[line.alcoholType] = [];
            }
            liquors[line.alcoholType].push(line.name);
          });
          setAvailableLiquors(liquors);
        } else {
          console.error("No settings data found!");
        }
      } catch (error) {
        console.error("Error fetching drink lines configuration:", error);
      }
    };

    fetchDrinkLinesConfig();
  }, []);

  // Update currentDrink when the drink prop changes
  useEffect(() => {
    if (drink) {
      const updatedDrink = JSON.parse(JSON.stringify(drink));
      if (updatedDrink.ingredients) {
        updatedDrink.ingredients = updatedDrink.ingredients.map((ing) => {
          if (ing.type === "poured" && !ing.alcoholType) {
            return { ...ing, alcoholType: ing.ingredient };
          }
          return ing;
        });
      }
      setCurrentDrink(updatedDrink);
    }
  }, [drink]);

  // Update slider values when currentDrink changes
  useEffect(() => {
    const initialSliderValues = {};
    if (currentDrink.ingredients) {
      currentDrink.ingredients.forEach((ingredient) => {
        if (ingredient.type === "poured") {
          initialSliderValues[ingredient.ingredient] = parseFloat(
            ingredient.amount.replace(" oz", "")
          );
        }
      });
    }
    setSliderValues(initialSliderValues);
    setOriginalSliderValues(initialSliderValues);
  }, [currentDrink]);

  // Fetch custom drink data if it exists for the user's business
  useEffect(() => {
    const fetchCustomDrink = async () => {
      if (businessName) {
        try {
          const customDrinkRef = doc(db, "drinks", businessName);
          const customDrinkDoc = await getDoc(customDrinkRef);
  
          if (customDrinkDoc.exists()) {
            const customDrinks = customDrinkDoc.data();
            if (customDrinks[currentDrink.cocktail]) {
              setIsCustomDrink(true);
              setCustomDrink(customDrinks[currentDrink.cocktail]);
              // Do NOT set currentDrink here
              setPrice(
                parseFloat(
                  customDrinks[currentDrink.cocktail].price.replace("$", "")
                ) || price
              );
              setInstructions(
                customDrinks[currentDrink.cocktail].instructions || ""
              );
            } else {
              setIsCustomDrink(false);
              setCustomDrink(null);
              setPrice(
                parseFloat(currentDrink.price.replace("$", "")) || price
              );
              setInstructions(currentDrink.instructions || "");
            }
          } else {
            setIsCustomDrink(false);
            setCustomDrink(null);
            setPrice(
              parseFloat(currentDrink.price.replace("$", "")) || price
            );
            setInstructions(currentDrink.instructions || "");
          }
        } catch (error) {
          console.error("Error fetching custom drink:", error);
          setIsCustomDrink(false);
          setCustomDrink(null);
        }
      } else {
        setIsCustomDrink(false);
        setCustomDrink(null);
        setPrice(parseFloat(currentDrink.price.replace("$", "")) || price);
        setInstructions(currentDrink.instructions || "");
      }
    };
    fetchCustomDrink();
  }, [
    businessName,
    currentDrink.cocktail,
    currentDrink.price,
    currentDrink.instructions,
    price,
  ]);
  

    // Effect to fetch the image URL from Firebase Storage when dark mode or image links change
    useEffect(() => {
      const fetchImageURL = async () => {
        const imagePath = darkMode ? drink.imageLinkDark : drink.imageLinkLight; // Choose image based on dark mode
        if (imagePath) {
          try {
            const imageRef = ref(storage, imagePath); // Reference to the image in Firebase Storage
            const url = await getDownloadURL(imageRef); // Get the download URL
            setImageURL(url); // Set the image URL state
          } catch (error) {
            console.error("Error fetching image from Firebase Storage:", error);
            setImageURL(null); // Set image URL to null on error
          }
        } else {
          setImageURL(null); // Set image URL to null if no image path
        }
      };
  
      fetchImageURL(); // Call the async function
    }, [darkMode, drink.imageLinkDark, drink.imageLinkLight]); // Dependencies

  // Memoize the checkIngredientAvailability function
  const checkIngredientAvailability = useCallback(() => {
    if (!drinkLinesConfig || !Array.isArray(drinkLinesConfig)) {
      console.error("Invalid drinkLinesConfig:", drinkLinesConfig);
      setConfigError(true);
      return [];
    }

    if (!currentDrink.ingredients || !Array.isArray(currentDrink.ingredients)) {
      console.error("Invalid ingredients data for drink:", currentDrink);
      return [];
    }

    return currentDrink.ingredients.filter((ing) => {
      if (ing.type !== "poured") return false;

      // Check if the specific brand name or its alcohol type is available in the drinkLinesConfig
      const isAvailable = drinkLinesConfig.some(
        (line) =>
          line.name === ing.ingredient || line.alcoholType === ing.alcoholType
      );

      return !isAvailable; // Return true if not available
    });
  }, [drinkLinesConfig, currentDrink]);

  // Check ingredient availability and set isAvailable state
  useEffect(() => {
    if (drinkLinesConfig.length > 0 && currentDrink.ingredients) {
      const unavailable = checkIngredientAvailability();
      setUnavailableIngredients((prev) => {
        // Use JSON.stringify for deep comparison; consider using a library like lodash.isEqual for production
        const areEqual = JSON.stringify(prev) === JSON.stringify(unavailable);
        return areEqual ? prev : unavailable;
      });
      setIsAvailable((prev) => {
        const newAvailability = unavailable.length === 0;
        return prev === newAvailability ? prev : newAvailability;
      });
    }
  }, [checkIngredientAvailability, currentDrink.ingredients, drinkLinesConfig.length]);

  // Display a snackbar with a given message
  const showSnackbar = useCallback((message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  }, []);

  // Handle closing the snackbar
  const handleSnackbarClose = useCallback((event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  }, []);

  const handleFlip = (e) => {
    if (!isEditing || (isEditing && e.target.closest(".back-face-content"))) {
      setIsFlipped(!isFlipped);
    }
  };
  

  const handleFlipBack = (e) => {
    if (isFlipped) {
      setIsFlipped(false);
    }
  };


// Handle ordering the drink
const handleOrder = useCallback(
  async (e) => {
    e.stopPropagation();

    if (!drinkLinesConfig) {
      console.error("Drink lines configuration is missing");
      setConfigError(true);
      return;
    }

    const unavailableIngredients = checkIngredientAvailability();
    if (unavailableIngredients.length > 0) {
      setUnavailableIngredients(unavailableIngredients);
      setShowUnavailableDialog(true);
      return;
    }

    const pouredIngredientsList = currentDrink.ingredients
      ? currentDrink.ingredients.filter(
          (ingredient) => ingredient.type === "poured"
        )
      : [];

    if (pouredIngredientsList.length === 0) {
      console.error("No poured ingredients found in the drink");
      showSnackbar("No poured ingredients found for this order.");
      return;
    }

    // Prepare MQTT message with line numbers and amounts
    const mqttMessage = pouredIngredientsList.map((ing) => {
      const lineConfig = drinkLinesConfig.find(
        (line) => line.alcoholType === ing.ingredient
      );
      if (lineConfig && lineConfig.id != null) {
        return {
          line: lineConfig.id,
          amount: parseFloat(sliderValues[ing.ingredient]) || parseFloat(ing.amount.replace(" oz", "")),
        };
      } else {
        console.warn(`Line number not found for ingredient: ${ing.ingredient}`);
        return null;
      }
    }).filter((item) => item !== null);

    try {
      if (client && connectionStatus === "Connected") {
        const topic = mqttSettings?.topic || "Aida/Sub";
        const message = JSON.stringify(mqttMessage);

        // Use the 'publish' function from MQTT context
        publish(topic, message);

        console.log("Published message to topic:", topic);
        console.log("Message:", message);
        showSnackbar("Drink order sent successfully");
      } else {
        showSnackbar("Drink order ready, but MQTT client not connected");
      }
    } catch (error) {
      console.error("Error during drink order:", error);
      showSnackbar("Failed to complete drink order. Please try again.");
    }

    if (onOrderDrink) {
      onOrderDrink(isCustomDrink ? customDrink : currentDrink);
    }
  },
  [
    client,
    connectionStatus,
    mqttSettings,
    publish,
    checkIngredientAvailability,
    currentDrink,
    sliderValues,
    showSnackbar,
    isCustomDrink,
    customDrink,
    onOrderDrink,
    drinkLinesConfig,
  ]
);



  // Handle changes in slider values for ingredients
  const handleSliderChange = useCallback(
    (ingredient) => (event, newValue) => {
      setSliderValues((prev) => ({
        ...prev,
        [ingredient]: newValue,
      }));
    },
    []
  );

  // Handle entering edit mode
  const handleEdit = useCallback(
    (e) => {
      e.stopPropagation();
      if (onEdit && (userRole === "editor" || userRole === "admin")) {
        onEdit(isCustomDrink ? customDrink : currentDrink);
        setIsFlipped(true);
      } else {
        showSnackbar("You do not have permission to edit this drink.");
      }
    },
    [onEdit, userRole, isCustomDrink, customDrink, currentDrink, showSnackbar]
  );

  // Handle closing edit mode
  const handleCloseEdit = useCallback(
    (e) => {
      e.stopPropagation();
      setSliderValues(originalSliderValues);
      setPrice(
        parseFloat(
          (isCustomDrink ? customDrink.price : currentDrink.price).replace(
            "$",
            ""
          )
        ) || price
      );
      setInstructions(
        isCustomDrink
          ? customDrink.instructions
          : currentDrink.instructions || ""
      );
      if (onCloseEdit) {
        onCloseEdit();
      }
    },
    [
      isCustomDrink,
      customDrink,
      currentDrink,
      originalSliderValues,
      price,
      onCloseEdit,
    ]
  );

  // Handle saving changes made during edit mode
  const handleSave = useCallback(
    async (e) => {
      e.stopPropagation();
      if (businessName) {
        // Update ingredients with new amounts and types
        const updatedIngredients = currentDrink.ingredients.map((ingredient) => {
          if (ingredient.type === "poured") {
            return {
              ...ingredient,
              amount: sliderValues[ingredient.ingredient].toFixed(1) + " oz",
              ingredient: ingredient.ingredient,
            };
          } else {
            return ingredient;
          }
        });

        const updatedDrink = {
          ...currentDrink,
          ingredients: updatedIngredients,
          price: `$${parseFloat(price).toFixed(2)}`,
          instructions: instructions,
          isCustomDrink: true,
          businessId: businessName,
        };

        try {
          const customDrinkRef = doc(db, "drinks", businessName);
          const customDrinkDoc = await getDoc(customDrinkRef);
          let customDrinks = customDrinkDoc.exists()
            ? customDrinkDoc.data()
            : {};
          customDrinks[currentDrink.cocktail] = updatedDrink;
          await setDoc(customDrinkRef, customDrinks);
          showSnackbar("Custom drink saved successfully");
          setCustomDrink(updatedDrink);
          setIsCustomDrink(true);
          setCurrentDrink(updatedDrink); // Update currentDrink with the saved version
        } catch (error) {
          console.error("Error saving custom drink:", error);
          showSnackbar("Failed to save custom drink. Please try again.");
        }
      }

      setOriginalSliderValues(sliderValues);
      if (onCloseEdit) {
        onCloseEdit();
      }
    },
    [
      businessName,
      currentDrink,
      sliderValues,
      price,
      instructions,
      showSnackbar,
      onCloseEdit,
    ]
  );

  // Handle image load event
  const handleImageLoad = useCallback(() => {
    setImageLoaded(true);
  }, []);

  // Handle price change
  const handlePriceChange = useCallback((e) => {
    setPrice(e.target.value);
  }, []);

  // Handle instructions change
  const handleInstructionsChange = useCallback((e) => {
    setInstructions(e.target.value);
  }, []);

  // Find the closest match for ingredient selection
  const findClosestMatch = useCallback((value, options) => {
    if (options.includes(value)) {
      return value;
    }
    // If the exact value isn't found, return the first option
    return options[0] || "";
  }, []);

  // Handle ingredient type change in edit mode
  const handleIngredientTypeChange = useCallback(
    (oldIngredientName) => (event) => {
      const newIngredient = event.target.value;

      setSliderValues((prev) => {
        const { [oldIngredientName]: _, ...rest } = prev;
        return {
          ...rest,
          [newIngredient]: prev[oldIngredientName] || 0,
        };
      });

      setCurrentDrink((prevDrink) => {
        const updatedIngredients = prevDrink.ingredients.map((ing) => {
          if (
            ing.ingredient === oldIngredientName &&
            ing.type === "poured"
          ) {
            return {
              ...ing,
              ingredient: newIngredient,
            };
          } else {
            return ing;
          }
        });
        return {
          ...prevDrink,
          ingredients: updatedIngredients,
        };
      });
    },
    []
  );

  // Memoize the handleToggleCustomDrink function
  const handleToggleCustomDrink = useCallback(() => {
    if (customDrink) {
      setIsCustomDrink((prevIsCustomDrink) => !prevIsCustomDrink);
      const drinkToDisplay = isCustomDrink ? drink : customDrink;
      setCurrentDrink(drinkToDisplay);
      setPrice(parseFloat(drinkToDisplay.price.replace("$", "")) || 0);
      setInstructions(drinkToDisplay.instructions || "");
    }
  }, [customDrink, drink, isCustomDrink]);

  const UnavailableIngredientsDialog = () => (
    <Dialog
      open={showUnavailableDialog}
      onClose={() => setShowUnavailableDialog(false)}
    >
      <DialogTitle>Unavailable Ingredients</DialogTitle>
      <DialogContent>
        <Typography>
          The following ingredients are currently unavailable:
        </Typography>
        <List>
          {unavailableIngredients.map((ing, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={ing.ingredient}
                secondary={`Not available. Replace bottle or check configuration settings.`}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShowUnavailableDialog(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );

  // Component to render a dialog when there is a configuration error
  const ConfigErrorDialog = () => (
    <Dialog open={configError} onClose={() => setConfigError(false)}>
      <DialogTitle>Configuration Error</DialogTitle>
      <DialogContent>
        <Typography>
          There was an error with the drink lines configuration. Please try
          again later or contact support.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setConfigError(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );

  // Determine the default image based on dark mode
  const defaultImage = darkMode
    ? "/generic-cocktail-icon.png"
    : "/generic-cocktail-img.png";
  const imageSrc = imageURL || defaultImage;

  // Styles for the card container
  const cardContainerStyle = {
    perspective: "1000px",
    width: isEditing ? "90%" : "100%",
    maxWidth: isEditing ? "400px" : "none",
    height: isEditing ? "90%" : "450px",
    maxHeight: isEditing ? "600px" : "none",
    position: isEditing ? "fixed" : "relative",
    top: isEditing ? "50%" : "auto",
    left: isEditing ? "50%" : "auto",
    transform: isEditing ? "translate(-50%, -50%) scale(1.0)" : "none",
    zIndex: isEditing ? 1000 : 1,
    transition: "all .7s ease",
  };

  // Styles for the card
  const cardStyle = {
    position: "relative",
    width: "100%",
    height: "100%",
    transition: "transform 0.6s",
    transformStyle: "preserve-3d",
    transform: isFlipped ? "rotateY(180deg)" : "rotateY(0deg)",
  };

  // Common styles for card faces
  const cardFaceStyle = {
    position: "absolute",
    width: "100%",
    height: "100%",
    backfaceVisibility: "hidden",
    borderRadius: "16px",
    overflow: "hidden",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
  };

  // Styles for the front face of the card
  const cardFrontStyle = {
    ...cardFaceStyle,
    backgroundColor: darkMode ? "#1a1a1a" : "#ffffff",
    color: darkMode ? "#fff" : "#424242",
  };

  // Styles for the back face of the card
  const cardBackStyle = {
    ...cardFaceStyle,
    backgroundColor: darkMode ? "#2a2a2a" : "#f0f0f0",
    color: darkMode ? "#fff" : "#424242",
    transform: "rotateY(180deg)",
    overflowY: "auto",
    zIndex: isFlipped ? 2 : 1, // Ensure back face is on top when flipped
    pointerEvents: isFlipped ? 'auto' : 'none', // Allow pointer events only when flipped
  };
  

  // Style for the edit/close button
  const buttonStyle = {
    position: "absolute",
    top: "8px",
    right: "8px",
    zIndex: 2,
  };

  // Get the list of poured ingredients
  const pouredIngredientsList = currentDrink.ingredients
    ? currentDrink.ingredients.filter(
        (ingredient) => ingredient.type === "poured"
      )
    : [];

  // Optional: Use 'loadingUserData' to show a loading spinner before data is loaded
  if (loadingUserData) {
    return (
      <div className="loading-container">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div
      style={cardContainerStyle}
      className={`card-container ${isEditing ? 'editing' : ''}`}
      onClick={handleFlip}
    >
      <Fade in={true} timeout={300}>
        <div style={cardContainerStyle}>
          <div style={cardStyle}>
            {/* Front face of the card */}
            <div style={cardFrontStyle}>
              <div className="card-media-container" style={{ position: 'relative' }}>
                {!imageLoaded && <CircularProgress className="loading-spinner" />}
                <CardMedia
                  component="img"
                  height="325"
                  image={imageSrc}
                  alt={drink.cocktail || 'Cocktail'}
                  onLoad={handleImageLoad}
                  style={{ display: imageLoaded ? 'block' : 'none' }}
                />
                {/* Availability banner */}
                {!isAvailable && (
                  <div
                  style={{
                    position: 'absolute',
                    top: '16px',
                    left: '-40px',
                    background: 'linear-gradient(90deg, #ff69b4, #00bfff)',
                    color: '#fff',
                    padding: '4px 8px',
                    transform: 'rotate(-45deg)',
                    width: '160px',
                    textAlign: 'center',
                    fontSize: '0.95rem',
                    fontFamily: "'Inter', -apple-system, sans-serif",
                    fontWeight: 500,
                    letterSpacing: '-0.01em',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                    zIndex: 10,
                  }}
                  >
                    Unavailable
                  </div>
                )}
              </div>
              <CardContent style={{ padding: '8px 16px' }}>
                <Typography variant="subtitle2" color="textSecondary" className="flavor-profile">
                  {(drink.flavorProfile || 'Unknown Flavor').toUpperCase()}
                </Typography>
                <Typography variant="h6" component="div" className="cocktail-name">
                  {drink.cocktail || 'Unknown Cocktail'}
                </Typography>
                {/* List of poured ingredients */}
                <Typography variant="body2" style={{ marginBottom: '8px' }}>
                  Alcohol:
                </Typography>
                <List dense style={{ padding: 0, marginBottom: '8px' }}>
                  {pouredIngredientsList.length > 0 ? (
                    pouredIngredientsList.map((ingredient, index) => {
                      // Ensure that ingredient.alcoholType and ingredient.ingredient are defined
                      const alcoholType = ingredient.alcoholType || 'unknownType';
                      const ingredientName = ingredient.ingredient || 'unknownIngredient';
                      const key = `${alcoholType}-${ingredientName}-${index}`;

                      // Debugging key
                      console.log('ListItem Key:', key);

                      return (
                        <ListItem
                          key={key}
                          style={{
                            padding: '0 0 1px 0',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <ListItemText primary={ingredientName} />
                        </ListItem>
                      );
                    })
                  ) : (
                    <ListItem style={{ padding: 0 }}>
                      <ListItemText primary="Unknown Type" />
                    </ListItem>
                  )}
                </List>
              </CardContent>
            </div>

            {/* Back face of the card */}
            <div style={cardBackStyle} onClick={handleFlipBack}>
              <CardContent
                className="back-face-content"
                style={{
                  padding: '16px',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
                onClick={(e) => {
                  if (isEditing) {
                    e.stopPropagation();
                  }
                }}
              >
                <div>
                  <Typography variant="h6" style={{ marginBottom: '8px' }}>
                    {drink.cocktail || 'Unknown Cocktail'}
                  </Typography>
                  {isEditing ? (
                    <TextField
                      label="Price"
                      value={price}
                      onChange={handlePriceChange}
                      type="number"
                      InputProps={{ inputProps: { min: 0, step: 0.01 } }}
                      size="small"
                      margin="normal"
                      style={{ width: '100px' }}
                    />
                  ) : (
                    <Typography variant="body2" style={{ marginBottom: '4px' }}>
                      Price: ${parseFloat(price).toFixed(2)}
                    </Typography>
                  )}
                  <Typography variant="body2" style={{ marginBottom: '4px' }}>
                    Flavor: {drink.flavorProfile || 'Unknown Flavor'}
                  </Typography>
                  <Typography variant="body2" style={{ marginBottom: '8px' }}>
                    Alcohol:
                  </Typography>
                  <List dense style={{ padding: 0, marginBottom: '8px' }}>
                    {pouredIngredientsList.length > 0 ? (
                      pouredIngredientsList.map((ingredient, index) => {
                        const isUnavailable = unavailableIngredients.some(
                          (unavailableIng) => unavailableIng.ingredient === ingredient.ingredient
                        );
                        const key = `${ingredient.alcoholType}-${ingredient.ingredient}-${index}`;

                        return (
                          <ListItem
                            key={key}
                            style={{
                              padding: '0 0 1px 0',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {isEditing ? (
                              <FormControl
                                variant="outlined"
                                size="small"
                                style={{ minWidth: 120 }}
                              >
                                <InputLabel>{ingredient.alcoholType}</InputLabel>
                                <Select
                                  value={findClosestMatch(
                                    ingredient.ingredient,
                                    availableLiquors[ingredient.alcoholType] || []
                                  )}
                                  onChange={handleIngredientTypeChange(ingredient.ingredient)}
                                  label={ingredient.alcoholType}
                                >
                                  {availableLiquors[ingredient.alcoholType]?.map((liquor) => (
                                    <MenuItem key={liquor} value={liquor}>
                                      {liquor}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            ) : (
                              <ListItemText
                                primary={ingredient.ingredient}
                                primaryTypographyProps={{
                                  style: {
                                    color: isUnavailable ? 'red' : 'inherit',
                                  },
                                }}
                              />
                            )}
                          </ListItem>
                        );
                      })
                    ) : (
                      <ListItem style={{ padding: 0 }}>
                        <ListItemText primary="Unknown Type" />
                      </ListItem>
                    )}
                  </List>
                  <Typography variant="h6" style={{ marginBottom: '8px' }}>
                    Instructions:
                  </Typography>
                  {isEditing ? (
                    <TextField
                      label="Instructions"
                      value={instructions}
                      onChange={handleInstructionsChange}
                      multiline
                      fullWidth
                      margin="normal"
                      size="small"
                    />
                  ) : (
                    <Typography variant="body2" style={{ marginBottom: '4px' }}>
                      {instructions || 'No instructions provided.'}
                    </Typography>
                  )}
                  {isEditing && (
                    <>
                      <Typography variant="subtitle2" style={{ marginBottom: '4px' }}>
                        Adjust Ingredients:
                      </Typography>
                      <List dense style={{ padding: 0, marginBottom: '8px' }}>
                        {Object.entries(sliderValues).map(([ingredient, amount]) => (
                          <ListItem
                            key={ingredient}
                            style={{
                              padding: '0 0 1px 0',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <ListItemText
                              primary={`${ingredient}: ${amount.toFixed(1)} oz`}
                              primaryTypographyProps={{
                                variant: 'body2',
                                style: { lineHeight: '1' },
                              }}
                            />
                            <Slider
                              value={amount}
                              onChange={handleSliderChange(ingredient)}
                              min={0}
                              max={3}
                              step={0.1}
                              className="interactive-element"
                              style={{
                                marginLeft: '16px',
                                width: '120px',
                              }}
                              aria-labelledby="ingredient-slider"
                            />
                          </ListItem>
                        ))}
                      </List>
                    </>
                  )}
                </div>

                {/* Drink flag on the back */}
                <Typography
                  variant="caption"
                  style={{
                    position: 'absolute',
                    top: '8px',
                    left: '16px',
                    color: darkMode ? '#fff' : '#424242',
                  }}
                >
                  {isCustomDrink ? 'Custom Drink' : 'Base Drink'}
                </Typography>

                {/* Buttons at the bottom */}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    padding: '16px 0',
                  }}
                >
                  {isEditing && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSave}
                      className="interactive-element"
                      fullWidth
                      style={{ marginBottom: '16px' }}
                    >
                      Save
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOrder}
                    className="order-button interactive-element"
                    fullWidth
                    disabled={isEditing || !isAvailable}
                  >
                    {isAvailable ? 'Order' : 'Unavailable'}
                  </Button>
                </div>
              </CardContent>

              {/* Edit or Close icon button */}
              {!isEditing && userRole && (userRole === 'editor' || userRole === 'admin') && (
                <IconButton
                  style={buttonStyle}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEdit(e);
                  }}
                  color="primary"
                  className="interactive-element"
                >
                  <EditIcon />
                </IconButton>
              )}
              {isEditing && (
                <IconButton
                  style={buttonStyle}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCloseEdit(e);
                  }}
                  color="primary"
                  className="interactive-element"
                >
                  <CloseIcon />
                </IconButton>
              )}
              {/* Toggle between Base and Custom drink */}
              {!isEditing && customDrink && (
                <IconButton
                  style={{ ...buttonStyle, right: '48px' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleToggleCustomDrink();
                  }}
                  color="primary"
                  className="interactive-element"
                >
                  <SwapHorizIcon />
                </IconButton>
              )}
            </div>
          </div>
        </div>
      </Fade>

      {/* Dialogs for errors and notifications */}
      <UnavailableIngredientsDialog />
      <ConfigErrorDialog />

      {/* Snackbar for displaying messages */}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </div>
  );
};

// Export the Card component
export default CardComponent;
