import React, { useState } from 'react';
import {
 Box,
 Typography,
 Paper,
 List,
 ListItemIcon,
 ListItemText,
 ListItemButton,
 Switch,
 Divider
} from '@mui/material';
import {
 Palette as PaletteIcon,
 VolumeUp as VolumeIcon,
 Security as SecurityIcon,
 Language as NetworkIcon,
 Build as ConfigIcon,
 Inventory as InventoryIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const SettingsPage = ({ darkMode, onToggleDarkMode }) => {
 const navigate = useNavigate();
 const [notificationsEnabled, setNotificationsEnabled] = useState(false);

 const handleNetworkClick = () => {
   navigate('/network');
 };

 const handleConfigurationClick = () => {
   navigate('/configuration');
 };

 const handleInventoryClick = () => {
   navigate('/inventory');
 };

 const handleSecurityClick = () => {
   console.log('Security settings clicked');
 };

 const handleNotificationsChange = (event) => {
   setNotificationsEnabled(event.target.checked);
 };

 return (
   <Box
     sx={{
       display: 'flex',
       justifyContent: 'center',
       alignItems: 'flex-start',
       minHeight: 'calc(100vh - 64px)',
       position: 'relative',
       zIndex: 1,
       marginTop: '64px',
       padding: '24px'
     }}
   >
     <Box
       sx={{
         width: '100%',
         maxWidth: '800px'
       }}
     >
       <Typography 
         variant="h4" 
         gutterBottom
         sx={{
           marginBottom: '24px',
           color: darkMode ? 'white' : 'inherit'
         }}
       >
         Settings
       </Typography>
       <Paper 
         elevation={3} 
         sx={{
           backgroundColor: darkMode ? 'rgba(66, 66, 66, 0.9)' : 'rgba(255, 255, 255, 0.9)',
           borderRadius: 2,
           color: darkMode ? 'white' : 'inherit'
         }}
       >
         <List>
           <ListItemButton onClick={handleConfigurationClick}>
             <ListItemIcon>
               <ConfigIcon sx={{ color: darkMode ? 'white' : 'inherit' }} />
             </ListItemIcon>
             <ListItemText 
               primary="Configuration Settings" 
               secondary="Manage drink/pump lines"
               sx={{
                 '& .MuiListItemText-primary': {
                   color: darkMode ? 'white' : 'inherit'
                 },
                 '& .MuiListItemText-secondary': {
                   color: darkMode ? 'rgba(255, 255, 255, 0.7)' : 'inherit'
                 }
               }}
             />
           </ListItemButton>
           <Divider sx={{ borderColor: darkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)' }} />
           
           <ListItemButton onClick={handleInventoryClick}>
             <ListItemIcon>
               <InventoryIcon sx={{ color: darkMode ? 'white' : 'inherit' }} />
             </ListItemIcon>
             <ListItemText 
               primary="Inventory Management" 
               secondary="Manage drinks and stock levels"
               sx={{
                 '& .MuiListItemText-primary': {
                   color: darkMode ? 'white' : 'inherit'
                 },
                 '& .MuiListItemText-secondary': {
                   color: darkMode ? 'rgba(255, 255, 255, 0.7)' : 'inherit'
                 }
               }}
             />
           </ListItemButton>
           <Divider sx={{ borderColor: darkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)' }} />
           
           <ListItemButton onClick={onToggleDarkMode}>
             <ListItemIcon>
               <PaletteIcon sx={{ color: darkMode ? 'white' : 'inherit' }} />
             </ListItemIcon>
             <ListItemText 
               primary="Theme" 
               secondary="Toggle between light and dark mode"
               sx={{
                 '& .MuiListItemText-primary': {
                   color: darkMode ? 'white' : 'inherit'
                 },
                 '& .MuiListItemText-secondary': {
                   color: darkMode ? 'rgba(255, 255, 255, 0.7)' : 'inherit'
                 }
               }}
             />
             <Switch
               edge="end"
               checked={darkMode}
               onClick={(e) => e.stopPropagation()}
               onChange={onToggleDarkMode}
             />
           </ListItemButton>
           <Divider sx={{ borderColor: darkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)' }} />
           
           <ListItemButton>
             <ListItemIcon>
               <VolumeIcon sx={{ color: darkMode ? 'white' : 'inherit' }} />
             </ListItemIcon>
             <ListItemText 
               primary="Notifications" 
               secondary="Manage notification preferences"
               sx={{
                 '& .MuiListItemText-primary': {
                   color: darkMode ? 'white' : 'inherit'
                 },
                 '& .MuiListItemText-secondary': {
                   color: darkMode ? 'rgba(255, 255, 255, 0.7)' : 'inherit'
                 }
               }}
             />
             <Switch
               edge="end"
               checked={notificationsEnabled}
               onChange={handleNotificationsChange}
             />
           </ListItemButton>
           <Divider sx={{ borderColor: darkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)' }} />
           
           <ListItemButton onClick={handleNetworkClick}>
             <ListItemIcon>
               <NetworkIcon sx={{ color: darkMode ? 'white' : 'inherit' }} />
             </ListItemIcon>
             <ListItemText 
               primary="Network" 
               secondary="Configure MQTT Network"
               sx={{
                 '& .MuiListItemText-primary': {
                   color: darkMode ? 'white' : 'inherit'
                 },
                 '& .MuiListItemText-secondary': {
                   color: darkMode ? 'rgba(255, 255, 255, 0.7)' : 'inherit'
                 }
               }}
             />
           </ListItemButton>
           <Divider sx={{ borderColor: darkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)' }} />
           
           <ListItemButton onClick={handleSecurityClick}>
             <ListItemIcon>
               <SecurityIcon sx={{ color: darkMode ? 'white' : 'inherit' }} />
             </ListItemIcon>
             <ListItemText 
               primary="Security" 
               secondary="Manage security settings"
               sx={{
                 '& .MuiListItemText-primary': {
                   color: darkMode ? 'white' : 'inherit'
                 },
                 '& .MuiListItemText-secondary': {
                   color: darkMode ? 'rgba(255, 255, 255, 0.7)' : 'inherit'
                 }
               }}
             />
           </ListItemButton>
         </List>
       </Paper>
     </Box>
   </Box>
 );
};

export default SettingsPage;