import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Avatar,
  Box,
} from "@mui/material";
import {
  Add as AddIcon,
  LocalBar,
  Queue,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import { getAuth } from "firebase/auth";
import '../styles/Header.css';
import { ReactComponent as Logo } from '../assets/logo.svg';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedButton, setSelectedButton] = useState("settings");
  const [userInitial, setUserInitial] = useState("");

  // Check if current path is homepage
  const isHomePage = location.pathname === '/';

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const initial = user.displayName 
        ? user.displayName.charAt(0).toUpperCase()
        : user.email.charAt(0).toUpperCase();
      setUserInitial(initial);
    }
  }, []);

  // If it's homepage, don't render the header
  if (isHomePage) {
    return null;
  }

  const handleButtonClick = (button) => {
    setSelectedButton(button);
    navigate(`/${button}`);
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  const iconButtonStyle = {
    marginLeft: 1,
    padding: '8px',
  };

  const avatarStyle = {
    width: 32,
    height: 32,
    fontSize: '1.2rem',
    fontWeight: 'bold',
  };

  const minimalPages = ['/login', '/register', '/reset-password'];
  const isMinimalPage = minimalPages.includes(location.pathname);

  return (
    <AppBar position="fixed" className="app-bar">
      <Toolbar className="toolbar">
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          {isMinimalPage ? (
            <>
              <Box flexGrow={1} />
              <IconButton 
                onClick={handleLogoClick} 
                className="icon-button"
              >
                <Logo className="app-logo" />
              </IconButton>
              <Box flexGrow={1} />
            </>
          ) : (
            <>
              <IconButton 
                onClick={handleLogoClick} 
                className="icon-button"
                sx={{ marginRight: 2 }}
              >
                <Logo className="app-logo" />
              </IconButton>
              <Box display="flex" alignItems="center" justifyContent="center" flexGrow={1}>
                <Button
                  className={`nav-button ${selectedButton === "order" ? "active" : ""}`}
                  startIcon={<LocalBar />}
                  onClick={() => handleButtonClick("order")}
                >
                  ORDER
                </Button>
                <Button
                  className={`nav-button ${selectedButton === "queue" ? "active" : ""}`}
                  startIcon={<Queue />}
                  onClick={() => handleButtonClick("queue")}
                >
                  QUEUE
                </Button>
              </Box>
              <Box display="flex" alignItems="center">
                <IconButton 
                  className="icon-button"
                  sx={iconButtonStyle}
                >
                  <AddIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleButtonClick("account")}
                  className={`icon-button ${selectedButton === "account" ? "active" : ""}`}
                  sx={iconButtonStyle}
                >
                  <Avatar 
                    className="profile-avatar"
                    sx={avatarStyle}
                  >
                    {userInitial}
                  </Avatar>
                </IconButton>
                <IconButton
                  onClick={() => handleButtonClick("settings")}
                  className={`icon-button ${selectedButton === "settings" ? "active" : ""}`}
                  sx={iconButtonStyle}
                >
                  <SettingsIcon />
                </IconButton>
              </Box>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;