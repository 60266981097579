import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { auth, db } from '../firebase';
import { doc, getDoc, setDoc} from 'firebase/firestore'; //collection, query, where, getDocs } from 'firebase/firestore';

const CompanySettingsContext = createContext();

export const useCompanySettings = () => {
  const context = useContext(CompanySettingsContext);
  if (context === undefined) {
    throw new Error('useCompanySettings must be used within a CompanySettingsProvider');
  }
  return context;
};

export const CompanySettingsProvider = ({ children }) => {
  const [companySettings, setCompanySettings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [companyId, setCompanyId] = useState(null);

  const fetchCompanySettings = useCallback(async (user) => {
    try {
      console.log("Fetching company settings for user:", user.uid);
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      const userCompanyId = userDoc.data()?.businessName;
      console.log("Company ID:", userCompanyId);

      if (!userCompanyId) {
        console.log("No company ID found for user");
        setCompanySettings({ drinkLinesConfig: [] });
        return;
      }

      setCompanyId(userCompanyId);
      const companyDoc = await getDoc(doc(db, 'settings', userCompanyId));
      console.log("Fetched Company Settings:", companyDoc.data());
      setCompanySettings(companyDoc.data() || { drinkLinesConfig: [] });
    } catch (err) {
      console.error("Error fetching company settings:", err);
      setError('Error fetching company settings: ' + err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    console.log("CompanySettingsProvider mounted");
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setLoading(true);
      setError(null);
      if (user) {
        console.log("User logged in:", user.uid);
        await fetchCompanySettings(user);
      } else {
        console.log("No user logged in");
        setCompanySettings(null);
        setCompanyId(null);
        setLoading(false);
      }
    });

    return () => {
      console.log("CompanySettingsProvider unmounted");
      unsubscribe();
    };
  }, [fetchCompanySettings]);

  const updateCompanySettings = useCallback(async (newSettings) => {
    setLoading(true);
    setError(null);

    if (!companyId) {
      console.error("No company ID available");
      setError('No company ID available');
      setLoading(false);
      return;
    }

    try {
      console.log("Updating settings:", newSettings);
      await setDoc(doc(db, 'settings', companyId), newSettings, { merge: false });
      const updatedDoc = await getDoc(doc(db, 'settings', companyId));
      console.log("Updated settings:", updatedDoc.data());
      setCompanySettings(updatedDoc.data());
    } catch (err) {
      console.error("Error updating company settings:", err);
      setError('Error updating company settings: ' + err.message);
    } finally {
      setLoading(false);
    }
  }, [companyId]);

  console.log("CompanySettingsProvider rendering", { companySettings, loading, error, companyId });

  return (
    <CompanySettingsContext.Provider value={{ companySettings, updateCompanySettings, loading, error, companyId }}>
      {children}
    </CompanySettingsContext.Provider>
  );
};