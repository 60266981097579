import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { Card, TextField, Button, Typography, Box, Alert } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const LoginPage = ({ darkMode }) => {
  const theme = useTheme();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const auth = getAuth();
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/order');
    } catch (error) {
      setError('Failed to log in. Please check your credentials.');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 'calc(100vh - 64px)',
        position: 'relative',
        zIndex: 1,
        marginTop: '64px',
        background: 'transparent',
      }}
    >
      <Card
        sx={{
          padding: 4,
          width: '400px',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
          borderRadius: 2,
          backgroundColor: darkMode ? '#242424' : '#f5f5f5',
          position: 'relative',
          overflow: 'hidden',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '4px',
            background: 'linear-gradient(90deg, #ff69b4, #00bfff)',
          },
        }}
      >
        <Typography 
          variant="h3" 
          align="center" 
          gutterBottom
          sx={{
            fontFamily: "'Inter', -apple-system, sans-serif",
            fontWeight: 700,
            fontSize: '2.2rem',
            letterSpacing: '-0.02em',
            color: theme.palette.text.primary,
            marginBottom: 3,
          }}
        >
          Login
        </Typography>
        {error && (
          <Alert 
            severity="error" 
            sx={{ 
              marginBottom: 2,
              fontFamily: "'Inter', -apple-system, sans-serif",
            }}
          >
            {error}
          </Alert>
        )}
        <form onSubmit={handleSubmit}>
          <TextField
            label="Email"
            type="email"
            fullWidth
            margin="normal"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            sx={{
              '& .MuiInputLabel-root': {
                fontFamily: "'Inter', -apple-system, sans-serif",
                fontSize: '0.95rem',
              },
              '& .MuiInputBase-input': {
                fontFamily: "'Inter', -apple-system, sans-serif",
                fontSize: '1rem',
              },
              marginBottom: 2,
            }}
          />
          <TextField
            label="Password"
            type="password"
            fullWidth
            margin="normal"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            sx={{
              '& .MuiInputLabel-root': {
                fontFamily: "'Inter', -apple-system, sans-serif",
                fontSize: '0.95rem',
              },
              '& .MuiInputBase-input': {
                fontFamily: "'Inter', -apple-system, sans-serif",
                fontSize: '1rem',
              },
              marginBottom: 2,
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              marginTop: 2,
              padding: '12px',
              background: 'linear-gradient(90deg, #ff69b4, #00bfff)',
              color: '#fff',
              fontFamily: "'Inter', -apple-system, sans-serif",
              fontWeight: 500,
              fontSize: '1rem',
              letterSpacing: '-0.01em',
              textTransform: 'none',
              '&:hover': {
                background: 'linear-gradient(90deg, #ff69b4, #00bfff)',
                opacity: 0.9,
              },
            }}
          >
            Login
          </Button>
        </form>
        <Box mt={3} textAlign="center">
          <Link
            to="/reset-password"
            style={{
              textDecoration: 'none',
              color: theme.palette.primary.main,
              fontFamily: "'Inter', -apple-system, sans-serif",
              fontSize: '0.95rem',
              fontWeight: 500,
            }}
          >
            Forgot Password?
          </Link>
        </Box>
        <Box mt={2} textAlign="center">
          <Typography 
            variant="body2"
            sx={{
              fontFamily: "'Inter', -apple-system, sans-serif",
              fontSize: '0.95rem',
              color: theme.palette.text.secondary,
            }}
          >
            Don't have an account?{' '}
            <Link
              to="/register"
              style={{
                textDecoration: 'none',
                color: theme.palette.primary.main,
                fontWeight: 500,
              }}
            >
              Register here
            </Link>
          </Typography>
        </Box>
      </Card>
    </Box>
  );
};

export default LoginPage;