import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  TextField,
  Button,
  Popover,
  MenuItem,
  InputAdornment,
  IconButton,
  Snackbar,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useCompanySettings } from '../contexts/CompanySettingsContext';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import mqtt from 'mqtt';

const InventoryPage = ({ drinkLinesConfig, onUpdateDrinkLines, darkMode }) => {
  const navigate = useNavigate();
  const { companySettings, updateCompanySettings } = useCompanySettings();
  const [inventory, setInventory] = useState([]);
  const [nextLineId, setNextLineId] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedLineId, setSelectedLineId] = useState(null);
  const [errors, setErrors] = useState({});
  const [client, setClient] = useState(null);
  const [mqttSettings, setMqttSettings] = useState(null);
  const [receivedMessages, setReceivedMessages] = useState([]); // New state for received messages
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const alcoholTypes = [
    "Whiskey", "Rum", "Tequila", "Vodka", "Gin", "Cachaça", "Pisco", "Cognac", 
    "Campari", "Triple Sec", "Sweet Vermouth", "Brandy", "Bourbon", "Scotch", 
    "Amaretto", "Absinthe", "Mezcal", "Aperol", "Amaro", "Frangelico", "Sambuca"
  ];

  const showSnackbar = useCallback((message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  }, []);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const fetchMqttSettings = useCallback(async () => {
    try {
      const docRef = doc(db, 'networkConfig', 'mqttSettings');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setMqttSettings(docSnap.data());
      } else {
        console.log("No MQTT settings found!");
        showSnackbar("MQTT settings not found. Please configure network settings.");
      }
    } catch (error) {
      console.error("Error fetching MQTT settings:", error);
      showSnackbar("Failed to fetch MQTT settings");
    }
  }, [showSnackbar]);

  useEffect(() => {
    fetchMqttSettings();
  }, [fetchMqttSettings]);

  useEffect(() => {
    if (mqttSettings) {
      const { brokerUrl, port, username, password, isSecure } = mqttSettings;
      const url = `${isSecure ? 'wss' : 'ws'}://${brokerUrl}:${port}/mqtt`;

      const mqttClient = mqtt.connect(url, {
        username,
        password,
        clientId: `mqttjs_${Math.random().toString(16).substr(2, 8)}`,
        rejectUnauthorized: false,
      });

      mqttClient.on('connect', () => {
        console.log('Connected to MQTT broker');
        setClient(mqttClient);

        // Subscribe to the MQTT topic for receiving messages
        const receiveTopic = mqttSettings.receiveTopic || 'Aida/Sub';
        mqttClient.subscribe(receiveTopic, (err) => {
          if (err) {
            console.error('Failed to subscribe to topic:', receiveTopic);
            showSnackbar('Failed to subscribe to receive topic');
          } else {
            console.log('Subscribed to topic:', receiveTopic);
          }
        });
      });

      mqttClient.on('message', (topic, message) => {
        const decodedMessage = message.toString();
        console.log('Received message:', decodedMessage);
        setReceivedMessages((prevMessages) => [
          ...prevMessages,
          { topic, message: decodedMessage, timestamp: new Date() },
        ]);
      });

      mqttClient.on('error', (err) => {
        console.error('MQTT connection error:', err);
        showSnackbar('Failed to connect to MQTT broker');
      });

      return () => {
        if (mqttClient) {
          mqttClient.end();
        }
      };
    }
  }, [mqttSettings, showSnackbar]);

  useEffect(() => {
    if (companySettings?.drinkLinesConfig) {
      setInventory(companySettings.drinkLinesConfig);
      const maxId = companySettings.drinkLinesConfig.reduce((max, line) => Math.max(max, line.id), 0);
      setNextLineId(maxId + 1);
    } else {
      setInventory([]);
      setNextLineId(1);
    }
  }, [companySettings]);

  const handleLineChange = (id, field, newValue) => {
    setInventory(prevInventory =>
      prevInventory.map(line =>
        line.id === id ? { ...line, [field]: newValue } : line
      )
    );
  };

  const handleAlcoholTypeClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedLineId(id);
  };

  const handleAlcoholTypeSelect = (newType) => {
    handleLineChange(selectedLineId, 'alcoholType', newType);
    setAnchorEl(null);
    setErrors(prevErrors => ({
      ...prevErrors,
      [selectedLineId]: undefined,
    }));
  };

  const validateInventory = () => {
    const newErrors = {};
    inventory.forEach(line => {
      if (!line.alcoholType) {
        newErrors[line.id] = "Please assign an alcohol type";
      }
      if (!line.name) {
        newErrors[line.id] = "Please assign a line name";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSaveInventory = async () => {
    if (validateInventory()) {
      try {
        // Add 'drink' property to each line as per your MQTT message structure
        const inventoryWithDrink = inventory.map(line => ({
          ...line,
          drink: ''
        }));

        const updatedSettings = {
          ...companySettings,
          drinkLinesConfig: inventoryWithDrink
        };
        await updateCompanySettings(updatedSettings);
        console.log('Inventory saved successfully!');

        // Publish the configuration via MQTT
        if (client && mqttSettings) {
          const topic = mqttSettings.topic || 'Aida/Sub';
          const message = JSON.stringify(inventoryWithDrink);
          client.publish(topic, message, (err) => {
            if (err) {
              console.error('Failed to publish configuration:', err);
              showSnackbar('Failed to publish configuration');
            } else {
              console.log('Configuration published successfully');
              showSnackbar('Configuration saved and published successfully');
            }
          });
        } else {
          showSnackbar('Configuration saved but MQTT client not connected');
        }

      } catch (error) {
        console.error('Error saving inventory:', error);
        showSnackbar('Failed to save inventory. Please try again.');
      }
    } else {
      showSnackbar('Please fix validation errors before saving');
    }
  };

  const handleAddLine = () => {
    setInventory(prevInventory => [
      ...prevInventory,
      { id: nextLineId, name: '', alcoholType: '', drink: '' }
    ]);
    setNextLineId(prevId => prevId + 1);
  };

  const handleDeleteLine = (id) => {
    setInventory(prevInventory => prevInventory.filter(line => line.id !== id));
    setErrors(prevErrors => {
      const newErrors = { ...prevErrors };
      delete newErrors[id];
      return newErrors;
    });
  };

  const handleBack = () => {
    navigate('/settings');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        minHeight: 'calc(100vh - 64px)',
        position: 'relative',
        zIndex: 1,
        marginTop: '64px',
        padding: '24px'
      }}
    >
      <Box sx={{ width: '100%', maxWidth: '800px' }}>
        <Paper 
          elevation={3} 
          sx={{ 
            p: 4,
            backgroundColor: darkMode ? 'rgba(66, 66, 66, 0.9)' : 'rgba(255, 255, 255, 0.9)',
            borderRadius: 2 
          }}
        >
          <Typography variant="h4" sx={{ mb: 4, color: darkMode ? 'white' : 'inherit' }}>
            Inventory Management
          </Typography>
          {inventory.length === 0 ? (
            <Typography variant="body1" sx={{ textAlign: 'center', my: 4, color: darkMode ? 'white' : 'inherit' }}>
              No lines configured. Click "Add Line" to start.
            </Typography>
          ) : (
            <>
              <List sx={{ maxHeight: '400px', overflowY: 'auto', mb: 2 }}>
                {inventory.map((line) => (
                  <ListItem key={line.id} sx={{ py: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                      <TextField
                        label={`Line ${line.id}`}
                        value={line.name || ''}
                        onChange={(e) => handleLineChange(line.id, 'name', e.target.value)}
                        variant="outlined"
                        size="small"
                        sx={{ flexGrow: 1, mr: 1 }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={(e) => handleAlcoholTypeClick(e, line.id)}>
                                <ArrowDropDownIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Typography
                        variant="body2"
                        sx={{
                          width: '120px',
                          textAlign: 'center',
                          color: darkMode ? 'rgba(255, 255, 255, 0.7)' : 'text.secondary',
                          mr: 1,
                        }}
                      >
                        {line.alcoholType || 'Not Assigned'}
                      </Typography>
                      <IconButton onClick={() => handleDeleteLine(line.id)} color="default">
                        <DeleteIcon sx={{ color: darkMode ? 'white' : 'inherit' }} />
                      </IconButton>
                    </Box>
                    {errors[line.id] && (
                      <Typography color="error" variant="caption" sx={{ mt: 0.5 }}>
                        {errors[line.id]}
                      </Typography>
                    )}
                  </ListItem>
                ))}
              </List>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                <Box>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleAddLine}
                    startIcon={<AddIcon />}
                    sx={{ mr: 2 }}
                  >
                    Add Line
                  </Button>
                  <Button onClick={handleBack} sx={{ color: darkMode ? 'rgba(255, 255, 255, 0.7)' : 'text.secondary' }}>
                    Back
                  </Button>
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveInventory}
                  startIcon={<SaveIcon />}
                >
                  Save Configuration
                </Button>
              </Box>
            </>
          )}
        </Paper>
 
        {/* Display Received MQTT Messages */}
        <Paper 
          elevation={3} 
          sx={{ 
            p: 4, 
            mt: 4,
            backgroundColor: darkMode ? 'rgba(66, 66, 66, 0.9)' : 'rgba(255, 255, 255, 0.9)',
            borderRadius: 2
          }}
        >
          <Typography variant="h5" sx={{ mb: 2, color: darkMode ? 'white' : 'inherit' }}>
            Received Messages
          </Typography>
          {receivedMessages.length === 0 ? (
            <Typography variant="body1" sx={{ textAlign: 'center', my: 2, color: darkMode ? 'white' : 'inherit' }}>
              No messages received yet.
            </Typography>
          ) : (
            <List sx={{ maxHeight: '300px', overflowY: 'auto' }}>
              {receivedMessages.map((msg, index) => (
                <ListItem key={index} sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                  <Typography variant="body2" sx={{ fontWeight: 'bold', color: darkMode ? 'white' : 'inherit' }}>
                    {msg.timestamp.toLocaleString()}
                  </Typography>
                  <Typography variant="body2" sx={{ color: darkMode ? 'white' : 'inherit' }}>
                    Topic: {msg.topic}
                  </Typography>
                  <Typography variant="body2" sx={{ color: darkMode ? 'white' : 'inherit' }}>
                    Message: {msg.message}
                  </Typography>
                </ListItem>
              ))}
            </List>
          )}
        </Paper>
 
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            style: { 
              maxHeight: '300px', 
              width: '200px',
              backgroundColor: darkMode ? '#424242' : '#fff'
            },
          }}
        >
          {alcoholTypes.map((type) => (
            <MenuItem 
              key={type} 
              onClick={() => handleAlcoholTypeSelect(type)}
              sx={{
                color: darkMode ? '#fff' : 'inherit'
              }}
            >
              {type}
            </MenuItem>
          ))}
        </Popover>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
        />
      </Box>
    </Box>
  );
};

export default InventoryPage;
