import React, { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { Card, TextField, Button, Typography, Box, Alert } from '@mui/material';
import { useTheme, alpha } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const ResetPasswordPage = () => {
  const theme = useTheme(); // Access the current theme
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      setMessage('Password reset email sent. Check your inbox.');
    } catch (error) {
      setError('Failed to send password reset email. ' + error.message);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center', // Center the Card vertically
        padding: '24px',
        position: 'relative',
        zIndex: 1,
        height: '100vh', // Set Box height to full viewport height
        boxSizing: 'border-box', // Include padding in height calculation
      }}
    >
      <Card
        sx={{
          padding: 3, // Reduced padding for a smaller box
          width: '100%',
          maxWidth: '400px', // Ensure the Card doesn't get too wide
          boxShadow: 3,
          borderRadius: 2,
          backgroundColor: alpha(theme.palette.background.paper, 0.9),
          margin: '0 auto', // Center the Card horizontally if width is less than the container
        }}
      >
        <Typography variant="h5" align="center" gutterBottom>
          Reset Password
        </Typography>
        {message && (
          <Alert severity="success" sx={{ marginBottom: 2 }}>
            {message}
          </Alert>
        )}
        {error && (
          <Alert severity="error" sx={{ marginBottom: 2 }}>
            {error}
          </Alert>
        )}
        <form onSubmit={handleSubmit}>
          <TextField
            label="Email"
            type="email"
            fullWidth
            margin="normal"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ marginTop: 2 }}
          >
            Send Reset Email
          </Button>
        </form>
        <Box mt={2} textAlign="center">
          <Typography variant="body2">
            Remembered your password?{' '}
            <Link
              to="/login"
              style={{
                textDecoration: 'none',
                color: theme.palette.text.secondary,
              }}
            >
              Login here
            </Link>
          </Typography>
        </Box>
      </Card>
    </Box>
  );
};

export default ResetPasswordPage;
