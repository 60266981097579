import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import debounce from 'lodash.debounce';
import { collection, getDoc, doc, setDoc } from 'firebase/firestore';
import { db, auth } from './firebase';
import { MQTTProvider, useMQTT } from './contexts/MQTTContext';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { CompanySettingsProvider, useCompanySettings } from './contexts/CompanySettingsContext';
import { lightTheme, darkTheme } from './theme/themes';

// Component imports
import Header from './components/Header';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import OrderPage from './pages/OrderPage';
import QueuePage from './pages/QueuePage';
import AccountPage from './pages/AccountPage';
import SettingsPage from './pages/SettingsPage';
import ConfigurationPage from './pages/ConfigurationPage';
import InventoryPage from './pages/InventoryPage';
import NetworkPage from './pages/NetworkPage';
import ProtectedRoute from './components/ProtectedRoute';
import backgroundVideo from "./assets/background.mp4"

// Background Video Component
const BackgroundVideo = () => {
  return (
    <div className="absolute top-0 left-0 w-full h-screen overflow-hidden -z-10">
      <video
        autoPlay
        loop
        muted
        playsInline
        className="w-full h-full object-cover"
        style={{ 
          position: 'fixed',
          top: '64px',
          left: 0,
          minWidth: '100%',
          minHeight: '100%',
          zIndex: -1000
        }}
      >
        <source src={backgroundVideo} type="video/mp4" />
      </video>
      <div className="absolute inset-0 bg-black/30" />
    </div>
  );
};

const MQTTHandler = ({ queue, completeDrink, companyId }) => {
  const { messages } = useMQTT();
  const [isProcessing, setIsProcessing] = useState(false);
  const [processedTags, setProcessedTags] = useState(new Set());

  useEffect(() => {
    if (!messages.length || !companyId || !queue.length || isProcessing) return;
    
    const latestMessage = messages[messages.length - 1];
    if (latestMessage.topic !== 'Aida/Scan') return;

    const handleRfidScan = async () => {
      try {
        const scanData = JSON.parse(latestMessage.message);
        if (!scanData.id || processedTags.has(scanData.id)) return;

        const drinkToComplete = queue[0];
        if (!drinkToComplete) return;

        console.log('Processing drink:', drinkToComplete);
        
        setIsProcessing(true);
        setProcessedTags(prev => new Set(prev).add(scanData.id));

        const settingsRef = doc(db, 'settings', companyId);
        const tagRef = doc(settingsRef, 'rfidTag', scanData.id);
        
        await setDoc(tagRef, {
          drink: drinkToComplete.cocktail,
          lastDrink: drinkToComplete.cocktail,
          lastUsed: new Date(),
          active: true,
          tagId: scanData.id
        }, { merge: true });

        setTimeout(() => {
          completeDrink(drinkToComplete);
          setIsProcessing(false);
          setTimeout(() => {
            setProcessedTags(prev => {
              const newSet = new Set(prev);
              newSet.delete(scanData.id);
              return newSet;
            });
          }, 5000);
        }, 3000);

      } catch (error) {
        console.error('MQTT Handler Error:', error);
        setIsProcessing(false);
      }
    };

    handleRfidScan();
  }, [messages, queue, completeDrink, companyId, isProcessing, processedTags]);

  return null;
};

const AppContent = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [drinks, setDrinks] = useState([]);
  const [queue, setQueue] = useState([]);
  const [completedDrinks, setCompletedDrinks] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [drinkLinesConfig, setDrinkLinesConfig] = useState([]);
  
  const { companyId, loading } = useCompanySettings();
  const { currentUser } = useAuth();
  const userId = currentUser?.uid;

  const handleSearchChange = useMemo(
    () => debounce((event) => setSearchQuery(event?.target?.value || ''), 300),
    []
  );

  useEffect(() => {
    return () => handleSearchChange.cancel();
  }, [handleSearchChange]);

  const fetchDrinks = useCallback(async () => {
    if (loading || !auth.currentUser) return;

    try {
      const drinksCollection = collection(db, 'drinks');
      const [baseDoc, companyDoc] = await Promise.all([
        getDoc(doc(drinksCollection, 'base')),
        companyId ? getDoc(doc(drinksCollection, companyId)) : Promise.resolve(null)
      ]);

      const baseDrinks = baseDoc.exists()
        ? Object.entries(baseDoc.data()).map(([key, value]) => ({
            id: key,
            ...value,
            isBaseDrink: true
          }))
        : [];

      let companyDrinks = [];
      if (companyId && companyDoc?.exists()) {
        companyDrinks = Object.entries(companyDoc.data()).map(([key, value]) => ({
          id: key,
          ...value,
          isBaseDrink: false
        }));
      }

      const mergedDrinksMap = new Map();
      baseDrinks.forEach(drink => mergedDrinksMap.set(drink.cocktail, drink));
      companyDrinks.forEach(drink => mergedDrinksMap.set(drink.cocktail, drink));

      setDrinks(Array.from(mergedDrinksMap.values()));
    } catch (error) {
      console.error("Error fetching drinks:", error);
    }
  }, [companyId, loading]);

  useEffect(() => {
    if (!loading && companyId) {
      fetchDrinks();
    }
  }, [fetchDrinks, companyId, loading]);

  const handleToggleDarkMode = useCallback(() => {
    setDarkMode(prev => !prev);
  }, []);

  const orderDrink = useCallback((drink) => {
    if (!drink?.cocktail) return;
    
    setQueue(prevQueue => {
      const drinkWithId = {
        ...drink,
        orderId: `${drink.cocktail}-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`
      };
      return [...prevQueue, drinkWithId];
    });
  }, []);

  const completeDrink = useCallback((drink) => {
    if (!drink?.orderId) return;

    console.log('Completing drink:', drink);
    setQueue(prev => {
      const newQueue = prev.filter(d => d.orderId !== drink.orderId);
      console.log('New queue:', newQueue);
      return newQueue;
    });
    setCompletedDrinks(prev => [...prev, drink]);
  }, []);

  return (
    <MQTTProvider userId={userId}>
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        <CssBaseline />
        <BackgroundVideo />
        <MQTTHandler 
          queue={queue}
          completeDrink={completeDrink}
          companyId={companyId}
        />
        <Router>
          <Header
            searchQuery={searchQuery}
            onSearchChange={handleSearchChange}
            darkMode={darkMode}
            onToggleDarkMode={handleToggleDarkMode}
          />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route
              path="/order"
              element={
                <ProtectedRoute>
                  <OrderPage
                    drinks={drinks}
                    darkMode={darkMode}
                    onOrderDrink={orderDrink}
                    companyId={companyId}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/queue"
              element={
                <ProtectedRoute>
                  <QueuePage
                    queue={queue}
                    completedDrinks={completedDrinks}
                    darkMode={darkMode}
                    onCompleteDrink={completeDrink}
                    businessName={companyId}
                  />
                </ProtectedRoute>
              }
            />
            <Route path="/settings" element={<ProtectedRoute><SettingsPage darkMode={darkMode} onToggleDarkMode={handleToggleDarkMode} /></ProtectedRoute>} />
            <Route path="/configuration" element={<ProtectedRoute><ConfigurationPage darkMode={darkMode} onSaveConfiguration={setDrinkLinesConfig} onToggleDarkMode={handleToggleDarkMode} /></ProtectedRoute>} />
            <Route path="/inventory" element={<ProtectedRoute><InventoryPage  drinkLinesConfig={drinkLinesConfig} onUpdateDrinkLines={setDrinkLinesConfig} darkMode={darkMode} /></ProtectedRoute>} />
            <Route path="/network" element={<ProtectedRoute><NetworkPage darkMode={darkMode} /></ProtectedRoute>} />
            <Route path="/account" element={<ProtectedRoute><AccountPage darkMode={darkMode} /></ProtectedRoute>} />
            <Route path="/login" element={<ProtectedRoute><LoginPage darkMode={darkMode} /></ProtectedRoute>} />
            <Route path='/register' element={<ProtectedRoute><RegisterPage darkMode={darkMode} /></ProtectedRoute>} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route path="/" element={<ProtectedRoute><HomePage darkMode={darkMode} /></ProtectedRoute>} />



          </Routes>
        </Router>
      </ThemeProvider>
    </MQTTProvider>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <CompanySettingsProvider>
        <AppContent />
      </CompanySettingsProvider>
    </AuthProvider>
  );
};

export default App;