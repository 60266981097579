import React, { useState, useEffect } from 'react';
import {
  Wine,
  Droplets,
  Timer,
  Gauge,
  Beaker,
  GlassWater,
  Martini,
  ArrowRightFromLine as Workflow,
  Github,
  Facebook,
  Twitter,
  Mail,
  User,
  Lock,
  X,
  Phone,
  MapPin,
  Download
} from 'lucide-react';
import { ReactComponent as Logo } from '../assets/logo.svg';
import '../styles/HomePage.css';
import AidaOverviewPDF from '../assets/Aida_Overview.pdf';


import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [activeTile, setActiveTile] = useState(null);
  const [showLogin, setShowLogin] = useState(false);
  const [loginStatus, setLoginStatus] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [error, setError] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => setScrollPosition(window.scrollY);
    const handleMouseMove = (e) =>
      setMousePosition({ x: e.clientX, y: e.clientY });

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setLoginStatus(true);
      } else {
        setLoginStatus(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleLogin = () => {
    setShowLogin(true);
  };

  const handleLogout = () => {
    const auth = getAuth();
    auth.signOut();
    setLoginStatus(false);
    navigate('/');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const auth = getAuth();
      await signInWithEmailAndPassword(auth, email, password);
      setLoginStatus(true);
      setShowLogin(false);
      setEmail('');
      setPassword('');
   
      navigate('/order');
    } catch (error) {
      setError('Failed to log in. Please check your credentials.');
    }
  };
  const Navigation = () => (
    <nav className={`cyber-nav ${scrollPosition > 50 ? 'scrolled' : ''}`}>
      <div className="nav-brand">
        <Logo className="app-logo" />
      </div>
      <div className="nav-controls">
        {loginStatus ? (
          <button className="nav-button user-profile" onClick={handleLogout}>
            <User size={18} />
            <span>Logout</span>
          </button>
        ) : (
          <button className="nav-button login-button" onClick={handleLogin}>
            <User size={18} />
            <span>Login</span>
          </button>
        )}
      </div>
    </nav>
  );

  const LoginModal = () => (
    <div className="login-modal">
      <div className="login-content">
        <button className="close-button" onClick={() => setShowLogin(false)}>
          <X size={20} />
        </button>
        <h2>Login</h2>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <div className="input-field">
              <User size={20} />
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="input-field">
              <Lock size={20} />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
          </div>
          <button className="login-button" type="submit">
            Sign In
          </button>
        </form>
        <div className="social-login">
          <p>Or continue with</p>
          <div className="social-buttons">
            <button className="social-btn github">
              <Github size={20} />
            </button>
            <button className="social-btn facebook">
              <Facebook size={20} />
            </button>
            <button className="social-btn twitter">
              <Twitter size={20} />
            </button>
            <button className="social-btn email">
              <Mail size={20} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  const ProcessSection = () => (
    <section className="process-section">
      <h2 className="section-title">How It Works</h2>
      <div className="process-timeline">
        <div className="timeline-item">
          <div className="timeline-icon">
            <Martini size={32} />
          </div>
          <div className="timeline-content">
            <h3>1. Select Drink</h3>
            <div className="timeline-details">
              <p>
                Browse our extensive collection of perfectly crafted recipes
              </p>
              <ul>
                <li>3600+ Premium Cocktails</li>
                <li>Customizable Options</li>
                <li>Recommended Drinks</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="timeline-item">
          <div className="timeline-icon">
            <Workflow size={32} />
          </div>
          <div className="timeline-content">
            <h3>2. Automated Mix</h3>
            <div className="timeline-details">
              <p>Watch as your drink is crafted with precision</p>
              <ul>
                <li>4-Second Preparation</li>
                <li>0.1ml Pour Accuracy</li>
                <li>Real-time Progress</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="timeline-item">
          <div className="timeline-icon">
            <GlassWater size={32} />
          </div>
          <div className="timeline-content">
            <h3>3. Enjoy</h3>
            <div className="timeline-details">
              <p>Savor your perfectly mixed drink</p>
              <ul>
                <li>Ideal Proportions</li>
                <li>Consistent Quality</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );



  /*** New Contact Us Section ***/
const ContactUsSection = () => (
  <section className="contact-section">
    <h2 className="section-title">Partner With Us</h2>
    <p className="contact-description">
        We are always looking for innovative partners to collaborate with. If you're interested in working with us, first drink's on us!
    </p>
    <div className="contact-info">
        <div className="contact-item">
          <Phone size={24} />
          <span>+1 (607) 592-1067</span>
        </div>
        <div className="contact-item">
          <Mail size={24} />
          <span>martinfieldsmiller@gmail.com</span>
        </div>
        <div className="contact-item">
          <MapPin size={24} />
          <span>Ithaca, New York</span>
        </div>
      <div className="contact-item">
      <a href={AidaOverviewPDF} download className="download-link">
      <Download size={24} />
      <span>Learn More</span>
      </a>
      </div>
    </div>
  </section>
);


  const Footer = () => (
    <footer className="cyber-footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3>About AIDA</h3>
          <p>
            AIDA is at the forefront of drink automation technology, providing innovative solutions for the modern world.
          </p>
        </div>
        <div className="footer-section">
          <h3>Quick Links</h3>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/order">Order</a></li>
            <li><a href="/partners">Partners</a></li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>Follow Us</h3>
          <div className="social-icons">
          <a href="https://facebook.com/aida" target="_blank" rel="noopener noreferrer"><Facebook size={24} /></a>
            <a href="https://twitter.com/aida" target="_blank" rel="noopener noreferrer"><Twitter size={24} /></a>
            <a href="https://github.com/aida" target="_blank" rel="noopener noreferrer"><Github size={24} /></a>
            <a href="mailto:martinfieldsmiller@gmail.com"><Mail size={24} /></a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© {new Date().getFullYear()} AIDA. All rights reserved.</p>
      </div>
    </footer>
  );

  return (
    <div className="cyber-container">
      <Navigation />
      {showLogin && <LoginModal />}

      <div className="cyber-grid">
        {[...Array(100)].map((_, i) => (
          <div
            key={i}
            className="grid-cell"
            style={{
              animationDelay: `${i * 0.1}s`,
              opacity: Math.max(
                0.1,
                1 - Math.abs(mousePosition.x - ((i % 10) * 100)) / 1000
              ),
            }}
          />
        ))}
      </div>

      <header className="glitch-header">
        <div className="glitch" data-text="AIDA">
          AIDA
        </div>
        <div className="cyber-subtitle">
          <Wine className="icon-glow" size={20} />
          <span>ARTISANAL INNOVATION FOR DRINK AUTOMATION</span>
          <Wine className="icon-glow" size={20} />
        </div>
      </header>

      <section className="cyber-stats">
        <div className="stat-item">
          <div className="stat-value">3600+</div>
          <div className="stat-label">Drink Recipes</div>
        </div>
        <div className="stat-item">
          <div className="stat-value">4s</div>
          <div className="stat-label">Average Mix Time</div>
        </div>
        <div className="stat-item">
          <div className="stat-value">99.9%</div>
          <div className="stat-label">Pour Accuracy</div>
        </div>
      </section>

      <section className="feature-matrix">
        {[
          {
            icon: <Droplets />,
            title: 'Precision Pouring',
            desc: '0.1ml accuracy',
          },
          {
            icon: <Timer />,
            title: 'Rapid Service',
            desc: '4-second drink preparation',
          },
          {
            icon: <Beaker />,
            title: 'Perfect Ratios',
            desc: 'Calibrated measurements',
          },
          {
            icon: <Gauge />,
            title: 'Real-time Monitoring',
            desc: 'Live pour tracking',
          },
        ].map((feature, index) => (
          <div
            key={index}
            className={`matrix-tile ${activeTile === index ? 'active' : ''}`}
            onMouseEnter={() => setActiveTile(index)}
            onMouseLeave={() => setActiveTile(null)}
          >
            <div className="tile-content">
              {feature.icon}
              <h3>{feature.title}</h3>
              <p>{feature.desc}</p>
            </div>
          </div>
        ))}
      </section>

      <ProcessSection />

      <section className="cyber-terminal">
        <div className="terminal-header">
          <span className="terminal-dot"></span>
          <span className="terminal-dot"></span>
          <span className="terminal-dot"></span>
        </div>
        <div className="terminal-body">
          <p>$ system_status: OPERATIONAL</p>
          <p>$ drink_options: READY</p>
          <p>$ dispensers: CALIBRATED</p>
          <p className="blink">$ ready_to_serve ▮</p>
        </div>
      </section>

      <ContactUsSection />

      <Footer />

      <div className="floating-menu">
        <button className="menu-item">
          <Martini />
        </button>
        <button className="menu-item">
          <Beaker />
        </button>
        <button className="menu-item">
          <Timer />
        </button>
      </div>
    </div>
  );
};

export default HomePage;
