import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage"; // Import Firebase storage

const firebaseConfig = {
  apiKey: "AIzaSyBXIE3g_F5-Qk9zESPbuf1fADLygzql8CI",
  authDomain: "aida-52f18.firebaseapp.com",
  projectId: "aida-52f18",
  storageBucket: "aida-52f18.appspot.com",
  messagingSenderId: "955030658137",
  appId: "1:955030658137:web:8c6063197bf79af3f82548",
  measurementId: "G-LM8B5C6EWN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const analytics = getAnalytics(app);
const storage = getStorage(app); // Initialize Firebase storage

export { app, auth, db, analytics, storage }; // Make sure storage is exported
