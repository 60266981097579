import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
  useMemo, // Add useMemo here
} from 'react';
import mqtt from 'mqtt';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from './AuthContext'; // Adjust the import path as needed

const MQTTContext = createContext();

export const useMQTT = () => useContext(MQTTContext);

export const MQTTProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const userId = currentUser?.uid;

  const clientRef = useRef(null);
  const [connectionStatus, setConnectionStatus] = useState('Disconnected');
  const [lastMessage, setLastMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [businessName, setBusinessName] = useState(null);
  const [mqttSettings, setMqttSettings] = useState(null);
  const [isMqttReady, setIsMqttReady] = useState(false);
  const [error, setError] = useState(null);
  const [isConnecting, setIsConnecting] = useState(false);

  // Fetch businessName based on the userId
  const fetchBusinessName = useCallback(async () => {
    if (!userId) return;
    try {
      const userDocRef = doc(db, 'users', userId);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        setBusinessName(userData.businessName);
      } else {
        console.error('No user data found');
      }
    } catch (error) {
      setError('Failed to fetch business name');
      console.error('Error fetching business name:', error);
    }
  }, [userId]);

  // Connect to MQTT broker
  const connect = useCallback((options) => {
    if (clientRef.current && clientRef.current.connected) {
      console.log('Already connected to MQTT broker');
      return;
    }

    console.log('Connecting to MQTT broker with options:', options);

    setError(null);

    const mqttClient = mqtt.connect({
      protocol: options.protocol || 'wss',
      hostname: options.hostname,
      port: options.port || 8884,
      path: options.path || '/mqtt',
      username: options.username,
      password: options.password,
      clientId: `mqttjs_${Math.random().toString(16).substr(2, 8)}`,
      rejectUnauthorized: false,
      keepalive: 60,
      clean: true,
    });

    clientRef.current = mqttClient;

    mqttClient.on('connect', () => {
      setConnectionStatus('Connected');
      setIsMqttReady(true);
      console.log('MQTT client connected');

      const topicToSubscribe = [
        options.topic || 'Aida/Orders',
        'Aida/Scan', 'Aida/Sub'  // Add RFID scan topic
      ];
      mqttClient.subscribe(topicToSubscribe, (err) => {
        if (err) {
          console.error(`Failed to subscribe: ${err.message}`);
        } else {
          console.log(`Subscribed to topic: ${topicToSubscribe}`);
        }
      });
    });

    mqttClient.on('message', (receivedTopic, message) => {
      const messageString = message.toString();
      console.log(
        'Received message on topic:',
        receivedTopic,
        'message:',
        messageString
      );
      setLastMessage(messageString);
      setMessages((prev) => [
        ...prev,
        {
          topic: receivedTopic,
          message: messageString,
          timestamp: new Date(),
        },
      ]);
    });

    mqttClient.on('error', (err) => {
      setConnectionStatus('Error');
      console.error(`MQTT Error: ${err.message}`);
    });

    mqttClient.on('close', () => {
      setConnectionStatus('Disconnected');
      setIsMqttReady(false);
      console.log('MQTT client disconnected');
    });

    mqttClient.on('reconnect', () => {
      setConnectionStatus('Reconnecting');
      console.log('MQTT client reconnecting');
    });

    mqttClient.on('offline', () => {
      setConnectionStatus('Offline');
      setIsMqttReady(false);
      console.log('MQTT client offline');
    });
  }, []);

  // Fetch MQTT settings based on businessName from Firestore
  useEffect(() => {
    const fetchMqttSettings = async () => {
      if (!businessName) return;
      try {
        const docRef = doc(db, 'settings', businessName);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const settings = docSnap.data().networkConfig;
          if (!settings.topic) {
            settings.topic = 'Aida/Orders'; // Default topic
          }
          setMqttSettings(settings);
          console.log('Fetched MQTT Settings:', settings);
        } else {
          console.log(`No settings found for ${businessName}, creating new entry.`);
        }
      } catch (error) {
        console.error('Error fetching MQTT settings:', error);
      }
    };

    fetchMqttSettings();
  }, [businessName]);

  // Fetch businessName when userId is available
  useEffect(() => {
    fetchBusinessName();
  }, [userId, fetchBusinessName]);

  // Connect to MQTT when mqttSettings are available
  useEffect(() => {
    if (mqttSettings && !clientRef.current && !isConnecting) {
      setIsConnecting(true);
      connect(mqttSettings);
    }
  }, [mqttSettings, connect, isConnecting]);

  // Handle userId changes (e.g., user logs out)
  useEffect(() => {
    if (!userId) {
      // User has logged out, disconnect MQTT
      if (clientRef.current) {
        clientRef.current.end();
        clientRef.current = null;
        setConnectionStatus('Disconnected');
        setIsMqttReady(false);
        console.log('MQTT client disconnected due to user logout');
      }
    }
  }, [userId]);

  // Disconnect from MQTT broker
  const disconnect = useCallback(() => {
    if (clientRef.current) {
      clientRef.current.end();
      setConnectionStatus('Disconnected');
      clientRef.current = null;
      setIsMqttReady(false);
      console.log('MQTT client disconnected via disconnect function');
    }
  }, []);

  // Publish a message to a topic
  const publish = useCallback((topic, message) => {
    if (clientRef.current && clientRef.current.connected) {
      clientRef.current.publish(topic, message);
      console.log(`Published message to ${topic}: ${message}`);
    } else {
      console.error('Not connected to broker');
    }
  }, []);

  // Save network configuration to Firestore
  const saveNetworkConfig = useCallback(
    async (networkConfig) => {
      if (!businessName) {
        console.error('Business name is not available');
        return;
      }

      try {
        const settingsDocRef = doc(db, 'settings', businessName);
        await setDoc(
          settingsDocRef,
          { networkConfig },
          { merge: true }
        );
        console.log(
          `Network config saved under settings/${businessName}`
        );
      } catch (error) {
        setError('Failed to save network configuration');
        console.error('Error saving network configuration:', error);
      }
    },
    [businessName]
  );

  const contextValue = useMemo(
    () => ({
      client: clientRef.current,
      connectionStatus,
      lastMessage,
      messages,
      connect,
      disconnect,
      publish,
      saveNetworkConfig,
      mqttSettings,
      error,
      isMqttReady,
    }),
    [
      connectionStatus,
      lastMessage,
      messages,
      connect,
      disconnect,
      publish,
      saveNetworkConfig,
      mqttSettings,
      error,
      isMqttReady,
    ]
  );

  return (
    <MQTTContext.Provider value={contextValue}>
      {children}
    </MQTTContext.Provider>
  );
};
