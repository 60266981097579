import React, { useState, useEffect, useCallback } from "react";
import { Avatar, Box, Typography, Snackbar, Button } from "@mui/material";
import { doc, setDoc } from 'firebase/firestore';
import { ref, getDownloadURL } from "firebase/storage";
import { useAuth } from "../contexts/AuthContext";
import { storage, db } from "../firebase";
import { useMQTT } from "../contexts/MQTTContext";
import { useTheme } from '@mui/material/styles';
import "../styles/QueuePage.css";
import logo from "../assets/logo.svg";
import placeholder from "../assets/grey-placeholder.png";

const QueuePage = ({ queue = [], completedDrinks = [], onCompleteDrink, businessName }) => {
  const theme = useTheme(); // Access the current theme
  const [queueWithImages, setQueueWithImages] = useState([]);
  const [completedWithImages, setCompletedWithImages] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const maxAvatars = 3;

  const { currentUser } = useAuth();
  const { client, connectionStatus, publish } = useMQTT();

  const showNotification = useCallback((message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  }, []);

  const fetchDrinkImages = useCallback(async (drinks) => {
    if (!drinks?.length) return [];

    const updatedDrinks = await Promise.all(
      drinks.map(async (drink) => {
        if (!drink) return null;
        try {
          const imagePath =
            theme.palette.mode === 'dark' ? drink.imageLinkDark : drink.imageLinkLight;
          if (!imagePath) return { ...drink, imageURL: placeholder };

          const imageRef = ref(storage, imagePath);
          const imageURL = await getDownloadURL(imageRef);
          return { ...drink, imageURL };
        } catch (error) {
          console.error("Error fetching image for drink:", drink.cocktail, error);
          return { ...drink, imageURL: placeholder };
        }
      })
    );

    return updatedDrinks.filter(Boolean);
  }, [theme.palette.mode]);

  useEffect(() => {
    const updateQueues = async () => {
      try {
        const [queueImages, completedImages] = await Promise.all([
          fetchDrinkImages(queue),
          fetchDrinkImages(completedDrinks.slice(-maxAvatars))
        ]);

        setQueueWithImages(queueImages);
        setCompletedWithImages(completedImages);
      } catch (error) {
        console.error("Error updating queues:", error);
        showNotification("Error loading drink images");
      }
    };

    updateQueues();
  }, [queue, completedDrinks, fetchDrinkImages, maxAvatars, showNotification]);

  const handleRfidScan = useCallback(async () => {
    if (!businessName || !queue.length || !currentUser) {
      showNotification("Unable to process RFID scan");
      return;
    }

    const orderedDrink = queue[0];

    try {
      const rfidId = prompt("Enter RFID ID:");
      if (!rfidId) {
        showNotification("No RFID ID provided");
        return;
      }

      const settingsRef = doc(db, "settings", businessName);
      const tagRef = doc(settingsRef, "rfidTag", rfidId);

      await setDoc(tagRef, {
        drink: orderedDrink.cocktail,
        lastDrink: orderedDrink.cocktail,
        lastUsed: new Date(),
        user: {
          id: currentUser.uid,
          email: currentUser.email || "Unknown Email",
        },
        businessName,
        active: true,
        tagId: rfidId,
        updatedBy: currentUser.uid,
      });

      if (client && connectionStatus === "Connected") {
        publish("Aida/Scan", JSON.stringify({ id: rfidId }));
        showNotification(`Processing drink: ${orderedDrink.cocktail}`);
      } else {
        showNotification("MQTT not connected");
      }
    } catch (error) {
      console.error("Error handling RFID scan:", error);
      showNotification("Error processing RFID scan");
    }
  }, [businessName, queue, currentUser, client, connectionStatus, publish, showNotification]);

  const handleSnackbarClose = useCallback(() => {
    setSnackbarOpen(false);
  }, []);

  const orderedDrink = queueWithImages[0] || null;
  const queuedDrinks = queueWithImages.slice(1, maxAvatars + 1);
  const remainingPlaceholders = Array(maxAvatars - queuedDrinks.length).fill(null);
  const completedDrinksWithPlaceholders = Array(maxAvatars)
    .fill(null)
    .map((_, i) => completedWithImages[i] || null);

  return (
    <div className={`page-container ${theme.palette.mode === 'dark' ? "dark-mode" : ""}`}>
      <img src={logo} alt="Logo" className="logo" />

      <Box className="queue-container">
        <div className="queue-row">
          {queuedDrinks.map((drink, index) => (
            <Avatar
              key={`queued-${index}-${drink?.cocktail}`}
              src={drink?.imageURL || placeholder}
              alt={drink?.cocktail || "Queued drink"}
              className="avatar"
              sx={{ width: 150, height: 150 }}
            />
          ))}
          {remainingPlaceholders.map((_, index) => (
            <Avatar
              key={`placeholder-${index}`}
              src={placeholder}
              alt="Empty slot"
              className="avatar"
              sx={{ width: 150, height: 150 }}
            />
          ))}
        </div>
        <Typography variant="h5" className="queue-label">
          QUEUED
        </Typography>
      </Box>

      <div className="ordered-avatar-container">
        {orderedDrink && (
          <Avatar
            src={orderedDrink.imageURL || placeholder}
            alt={orderedDrink.cocktail || "Current drink"}
            className="ordered-avatar"
            sx={{ width: 150, height: 150 }}
          />
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleRfidScan}
          disabled={!orderedDrink}
          style={{ marginTop: "20px" }}
        >
          Scan RFID
        </Button>
      </div>

      <Box className="completed-container">
        <div className="completed-row">
          {completedDrinksWithPlaceholders.map((drink, index) => (
            <Avatar
              key={`completed-${index}-${drink?.cocktail}`}
              src={drink?.imageURL || placeholder}
              alt={drink?.cocktail || "Completed drink"}
              className="completed-avatar"
              sx={{ width: 150, height: 150 }}
            />
          ))}
        </div>
        <Typography variant="h5" className="completed-label">
          COMPLETED
        </Typography>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      />
    </div>
  );
};

export default QueuePage;
