import React, { useState, useEffect } from 'react';
import { Grid, Container, IconButton, InputBase, Menu, MenuItem, Box } from '@mui/material';
import Card from '../components/Card';
import { LocalDrink as AlcoholIcon, Search as SearchIcon } from '@mui/icons-material';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useCompanySettings } from '../contexts/CompanySettingsContext';
import '../styles/OrderPage.css';

const OrderPage = ({ drinks, darkMode, onOrderDrink }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortCriteria, setSortCriteria] = useState('');
  const [editingDrinkId, setEditingDrinkId] = useState(null);
  const [drinkLinesConfig, setDrinkLinesConfig] = useState([]);
  const { companyId } = useCompanySettings();

  useEffect(() => {
    const fetchDrinkLinesConfig = async () => {
      if (companyId) {
        const settingsDoc = await getDoc(doc(db, 'settings', companyId));
        if (settingsDoc.exists()) {
          const data = settingsDoc.data();
          setDrinkLinesConfig(data.drinkLinesConfig || []);
        }
      }
    };
  
    fetchDrinkLinesConfig();
  }, [companyId]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (criteria) => {
    setSortCriteria(criteria);
    handleMenuClose();
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleEdit = (drink) => {
    setEditingDrinkId(drink.id);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseEdit = () => {
    setEditingDrinkId(null);
    document.body.style.overflow = 'auto';
  };

  const getMatchScore = (drink) => {
    const query = searchQuery.toLowerCase();
    const cocktail = drink.cocktail.toLowerCase();
    if (cocktail.startsWith(query)) return 3;
    if (cocktail.includes(query)) return 2;
    return 1;
  };

  const sortedDrinks = [...drinks].sort((a, b) => {
    const scoreA = getMatchScore(a);
    const scoreB = getMatchScore(b);

    if (scoreA !== scoreB) return scoreB - scoreA;

    if (sortCriteria) {
      if (a.alcoholType === sortCriteria && b.alcoholType !== sortCriteria) return -1;
      if (a.alcoholType !== sortCriteria && b.alcoholType === sortCriteria) return 1;
    }

    return 0;
  });

  return (
    <Container 
      sx={{
        paddingTop: '120px', // Account for header and search bar
        position: 'relative',
        minHeight: 'calc(100vh - 64px)',
      }}
    >
      {/* Fixed top bar containing search and filter */}
      <Box
        sx={{
          position: 'fixed',
          top: '64px',
          left: 0,
          right: 0,
          padding: '8px 16px',
          zIndex: 1200,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: 2
        }}
      >
        <IconButton 
          color="inherit" 
          onClick={handleMenuClick}
          sx={{ flexShrink: 0 }}
        >
          <AlcoholIcon />
        </IconButton>
        
        <Box
          sx={{
            backgroundColor: darkMode ? 'rgba(255,255,255,0.05)' : 'white',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            padding: '0 8px',
            width: '100%',
            maxWidth: '400px',
            boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
          }}
        >
          <SearchIcon color="inherit" />
          <InputBase
            placeholder="Search…"
            value={searchQuery}
            onChange={handleSearchChange}
            sx={{
              marginLeft: 1,
              flex: 1,
              color: 'inherit'
            }}
            inputProps={{ "aria-label": "search" }}
          />
        </Box>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <MenuItem onClick={() => handleMenuItemClick("Whiskey")}>Whiskey</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("Rum")}>Rum</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("Tequila")}>Tequila</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("Vodka")}>Vodka</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("Gin")}>Gin</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("Cachaça")}>Cachaça</MenuItem>
        </Menu>
      </Box>

      {/* Grid of drinks */}
      <Grid container spacing={3} justifyContent="start">
        {sortedDrinks.map((drink, index) => (
          <Grid 
            item 
            key={index} 
            xs={12} 
            sm={6} 
            md={4} 
            lg={3}
            sx={{
              transition: 'filter 0.3s ease',
              filter: editingDrinkId && editingDrinkId !== drink.id ? 'blur(5px)' : 'none',
            }}
          >
            <Card 
              drink={drink} 
              darkMode={darkMode} 
              onOrderDrink={onOrderDrink}
              onEdit={handleEdit}
              isEditing={editingDrinkId === drink.id}
              onCloseEdit={handleCloseEdit}
              drinkLinesConfig={drinkLinesConfig}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default OrderPage;